import create from 'zustand';
import { devtools } from 'zustand/middleware';
import produce from 'immer';

// export const useStore = create<any>(
//   devtools((set) => ({
//       planetNames: [],
//       getPlanetNames: async () => {
//           const planetsData = await (
//               await fetch("https://swapi.dev/api/planets")
//           ).json();

//           set({ planetNames: planetsData.results.map((pd: any) => pd.name) });
//       },
//       setPlanetNames: (data: any) => set({ planetNames: data })
//   }))
// );

const useDialogStore = create(
  devtools(
    (set) => ({
      //
      title: false,
      visible: false,
      answered: false,
      answer: null,
      textCancel: 'nee',
      textProceed: 'ja',
      setVisible: (visible) => set({ visible: visible }),
      setTitle: (title) => set({ title: title }),
      setAnswer: (answer) => set({ answered: true, answer: answer }),
      setText: (title, textCancel, textProceed) => set({ title: title, textCancel: textCancel, textProceed: textProceed }),
      reset: () => set({ answered: false, answer: null }),
      setConfirm: (proceed) => {
        set((state) => ({
          ...state,
          proceed: () => {
            // console.log('aa');
          }
        }));
      }
      //   setConfirm: (proceed) => set({ proceed: () => {}, cancel: 'b' })
    }),
    { name: 'dialogStore' }
  )
);

export default useDialogStore;

// const useWorkStore = create(
//   devtools(
//     (set) => ({

//       //
//       currentLineId: null,
//       thumb: "",
//       illustration: [],
//       lines: [],
//       nrOfEggsFound: 0,
//       setIncreaseFoundEggs: () => {
//         set((state) => ({
//           ...state,
//           nrOfEggsFound: state.nrOfEggsFound + 1,
//         }));
//       },
//       setThumb: (thumb) => {
//         set((state) => ({ ...state, thumb }));
//       },
//       setCurrentLineId: (currentLineId) => {
//         set((state) => ({
//           currentLineId: currentLineId,
//         }));
//       },
//       addIllustrationPart: (illustrationPart) => {
//         set((state) => ({
//           illustration: [...state.illustration, illustrationPart],
//         }));
//       },
//       addLine: (line, position = -1) => {
//         // [...state.currentWork.layers].splice(position, 0, "Lene");
//         set((state) => {
//           const lines = [...state.lines];
//           if (lines.length > 0) {
//             lines.splice(position, 0, line);
//           } else {
//             lines.push(line);
//           }
//           return {
//             ...state,
//             lines: lines,
//           };
//         });
//       },
//       removeLine: (lineId) => {
//         set((state) => ({
//           ...state,
//           lines: state.lines.filter((l) => l.id !== lineId),
//         }));
//       },
//       removeIllustrationPart: (illustrationPartId) => {
//         set((state) => ({
//           ...state,
//           illustration: state.illustration.filter(
//             (l) => l.id !== illustrationPartId
//           ),
//         }));
//       },
//       loadWork: (work) => {
//         set((state) => {
//           return {
//             ...state,
//             illustration: [...work.illustration],
//             lines: [...work.lines],
//           };
//         });
//       },

//       resetWork: () => {
//         set((state) => ({
//           illustration: [],
//           lines: [],
//           currentLineId: null,
//           nrOfEggsFound: 0,
//         }));
//       },
//     }),
//     { name: "workStore" }
//   )
// );
