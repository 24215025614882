//https://blog.logrocket.com/build-modal-with-react-portals/
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import Overlay from '../Overlay';
import { Transition } from 'react-transition-group';
import { useRef } from 'react';
import gsap from 'gsap';
import useWorkStore from '../../store/workStore';
import ColorList from '../ColorList';
import SelectIllustration from './SelectIllustration';
import SelectSentence from './SelectSentence';
import { colors } from '../../common/Color';
function StartNew({ allowedLayers, onContentSelect, visible, onClose }) {
  const nodeRef = useRef(null);

  const [selectedColor, setSelectedColor] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState(false);
  const [noAnimation, setNoAnimation] = useState(true);

  const setWorkColor = useWorkStore((state) => state.setWorkColor);
  const workColor = useWorkStore((state) => state.workColor);

  // reset
  useEffect(() => {}, [setSelectedMethod]);

  useEffect(() => {
    // setWorkColor(colors[Math.round(Math.random() * (colors.length - 1))]);
    // const [activeColor, setActiveColor] = useState(colors[Math.round(Math.random() * (colors.length - 1))]);
  }, []);

  useEffect(() => {
    if (visible) {
      setWorkColor(colors[Math.round(Math.random() * (colors.length - 1))]);
    }
  }, [visible]);

  useEffect(() => {
    setSelectedColor(workColor);
  }, [workColor]);
  // useLayoutEffect(() => {
  //   setWorkColor(selectedColor);
  // }, [selectedColor, setWorkColor]);

  // const onCloseOverlay = () => {
  //   setNoAnimation(false);
  //   setVisible(false);
  // };

  const onSelectContent = (data) => {
    onContentSelect({ color: selectedColor, type: selectedMethod, ...data });
    setNoAnimation(false);
    onClose();
    setSelectedMethod(false);
  };

  const onSelectColor = (color) => {
    setSelectedColor(color);
    setWorkColor(color);
  };
  const onEnter = () => {
    gsap.fromTo(nodeRef.current, { x: '100%' }, { duration: 0.2, x: '0%' });
  };
  const onExit = () => {};

  const onMethodSelect = (method) => {
    setSelectedMethod(method);
    setWorkColor(selectedColor);
    // onStart({ color: selectedColor, method: method });
  };

  return (
    <Overlay isVisible={visible} overlayType={'full'} noAnimation={noAnimation} handleClose={onClose}>
      <div className="wizard">
        <div className="wizard__page">
          <div className="wizard__header">
            <h1>Je gaat een vers werk maken!</h1>
          </div>

          <div className="wizard__section">
            <h3>Kies een kleur</h3>
            <ColorList currentColor={workColor} colors={colors} onColorSelect={onSelectColor} />
          </div>

          <div className="wizard__section">
            {/* <h3>Hoe wil je beginnen</h3> */}
            <div className="wizard__start-method">
              <button className="pill-button pill-button--large is-black" onClick={() => onMethodSelect('sentence')}>
                zin
              </button>
              <hr />
              <button className="pill-button pill-button--large is-black" onClick={() => onMethodSelect('illustration')}>
                illustratie
              </button>
            </div>
          </div>
        </div>

        <Transition
          in={selectedMethod ? true : false}
          onEnter={onEnter}
          onExit={onExit}
          mountOnEnter
          unmountOnExit
          timeout={500}
          nodeRef={nodeRef}>
          <div className="wizard__page" ref={nodeRef}>
            {selectedMethod === 'illustration' && (
              <SelectIllustration allowedLayers={allowedLayers} onSelect={onSelectContent}></SelectIllustration>
            )}
            {selectedMethod === 'sentence' && <SelectSentence onSelect={onSelectContent}></SelectSentence>}
            {/* component: sentences
            <br /> component: illustrations
            <br />
            <button onClick={onSelectContent}>select content</button> */}
          </div>
        </Transition>
      </div>
    </Overlay>
  );
}

export default StartNew;
