import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Pagination, Navigation } from 'swiper';
import StartNew from '../components/Overlays/StartNew';
import { ReactComponent as IconCircleArrow } from '../assets/icons/icon-circle-arrow.svg';
import { ReactComponent as VerversLogo } from '../assets/images/ververs-logo.svg';

import useStore from '../store/store';
import { useNavigate } from 'react-router-dom';

function PageIntroduction() {
  const navigate = useNavigate();

  const setIsNotFirstUse = useStore((state) => state.setIsNotFirstUse);
  const setAfterFirstUse = useStore((state) => state.setAfterFirstUse);

  const [swiper, setSwiper] = useState(false);
  const [lastSlide, setLastSlide] = useState(false);

  const onNextClick = () => {
    if (!swiper) return;
    swiper.slideNext();
  };

  const onClickStart = () => {
    setIsNotFirstUse(true);
    // setAfterFirstUse();
    // navigate('/generator');
    navigate('/generator', { state: { showWizard: true } });
  };
  return (
    <div className="page-introduction">
      <div className="help-swiper">
        <Swiper
          modules={[Pagination, Navigation]}
          spaceBetween={0}
          slidesPerView={1}
          onSlideChange={(swiper) => {
            // console.log(swiper.isEnd);
            if (swiper.isEnd) {
              setLastSlide(true);
            } else {
              setLastSlide(false);
            }
          }}
          onSwiper={setSwiper}
          pagination={{
            type: 'bullets'
          }}>
          <SwiperSlide>
            <div className="help-slide">
              <div className="help-slide__intro">
                <figure className="help-slide__logo">
                  <VerversLogo />
                </figure>

                <p>In de ver+vers app ga je aan de slag met de illustraties van Lies en de zinnen van Vicky.</p>
                <figure className="help-slide__image">
                  <img src="./images/vicky-lies.jpg" alt="Illustratie met de makers Vicky en Lies."></img>
                </figure>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="help-slide">
              <div className="help-slide__header">
                <h2>Hoe het werkt</h2>
              </div>
              <div className="help-slide__content">
                <div className="help-list">
                  <section>
                    <figure>
                      <img src="./assets/animations/intro-ani-1.webp" alt=""></img>
                      {/* <video
                        src="./assets/animations/intro-ani-1.mp4"
                        playsInline={true}
                        autoPlay={true}
                        loop="loop"
                        muted="muted"></video> */}
                    </figure>
                    <p>Je stelt een gedicht samen met de zinnen van Vicky.</p>
                  </section>
                  <section>
                    <figure>
                      <img src="./assets/animations/intro-ani-2.webp" alt=""></img>

                      {/* <video
                        src="./assets/animations/intro-ani-2.mp4"
                        playsInline={true}
                        autoPlay={true}
                        loop="loop"
                        muted="muted"></video> */}
                    </figure>
                    <p>Bij elke zin verschijnt een illustratie van Lies.</p>
                  </section>
                  <section>
                    <figure>
                      <img src="./assets/animations/intro-ani-3.webp" alt=""></img>

                      {/* <video
                        src="./assets/animations/intro-ani-3.mp4"
                        playsInline={true}
                        autoPlay={true}
                        loop="loop"
                        muted="muted"></video> */}
                    </figure>
                    <p>Omdraaien mag ook. Kies eerst een illustratie en kijk welke zin er verschijnt.</p>
                  </section>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="help-slide">
              <div className="help-slide__header">
                <h2>Blij met je resultaat?</h2>
              </div>
              <div className="help-slide__content">
                <div className="help-list">
                  <section>
                    <figure>
                      <img src="./assets/animations/intro-ani-4.webp" alt=""></img>

                      {/* <video
                        src="./assets/animations/intro-ani-4.mp4"
                        playsInline={true}
                        autoPlay={true}
                        loop="loop"
                        muted="muted"></video> */}
                    </figure>
                    <p>Deel je werk met vrienden.</p>
                  </section>
                  <section>
                    <figure>
                      <img src="./assets/animations/intro-ani-5.webp" alt=""></img>

                      {/* <video
                        src="./assets/animations/intro-ani-5.mp4"
                        playsInline={true}
                        autoPlay={true}
                        loop="loop"
                        muted="muted"></video> */}
                    </figure>
                    <p>Tijdens het samenstellen kun je tokens verzamelen. Laat je inspireren!</p>
                  </section>
                </div>
                {lastSlide && (
                  <section className="help-start">
                    <button className="pill-button is-primary  pill-button--large" onClick={onClickStart}>
                      start
                    </button>
                  </section>
                )}
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        <div className="help-swiper__controls">
          {!lastSlide && (
            <button className="circle-button" onClick={onNextClick}>
              <IconCircleArrow />
            </button>
          )}
        </div>
      </div>

      {/* {showStartOverlay && <StartNew></StartNew>} */}
      {/* <div className="page-introduction__header">
        <h1>Hallo! lorem ipsum dolor sit amet</h1>
      </div>
      <div className="page-introduction__content">
        <div className="features-list">
          <div className="features-list__item">
            <figure></figure>
            <p>Combineer zinnen en illustraties van Vicky en Lies en creeër je eigen werk!</p>
          </div>
          <div className="features-list__item">
            <figure></figure>
            <p>Ontdek en verzamel de inspirerende content tussen de regels.</p>
          </div>
          <div className="features-list__item">
            <figure></figure>
            <p>Maak, sla op en deel je werk.</p>
          </div>
        </div>
      </div>

      <div className="page-introduction__footer">
        <button className="pill-button pill-button--filled" onClick={onClickStart}>
          Beginnen
        </button>
      </div> */}
    </div>
  );
}

export default PageIntroduction;
