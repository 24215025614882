import { useEffect, useState, useRef } from 'react';
import useContainerDrag from '../hooks/useContainerDrag';

function ChoiceList({ choices, selected, wildcardLabel = false, onSelect, selectedValue, defaultSelected = null }) {
  const isMouseDevice = matchMedia('(hover:hover)').matches;
  const scrollerRef = useRef(null);

  const { handleMouseDown, handleMouseMove, handleMouseUp } = useContainerDrag(scrollerRef);

  return (
    <ul
      className={`choice-list ${isMouseDevice ? 'use-mouse' : ''}  `}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseUp}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      ref={scrollerRef}>
      {wildcardLabel && (
        <li className="choice-list__item">
          <button className={`pill-button ${selected === null ? 'is-active' : ''} `} onClick={() => onSelect(null)}>
            {wildcardLabel}
          </button>
        </li>
      )}
      {choices.map((choice, index) => (
        <li className="choice-list__item" key={index}>
          <button className={`pill-button ${selected === choice.id ? 'is-active' : ''}`} onClick={() => onSelect(choice.id)}>
            {choice.label}
          </button>
        </li>
      ))}
    </ul>
  );
}

export default ChoiceList;
