import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useRef } from 'react';
import gsap from 'gsap';

function ColorList({ currentColor, colors, onColorSelect }) {
  // set a random color as a start
  const [activeColor, setActiveColor] = useState(colors[Math.round(Math.random() * (colors.length - 1))]);

  // useEffect(() => {
  //   onColorSelect(activeColor);
  // }, [activeColor]);

  const onColorClick = (color) => {
    onColorSelect(color);
    setActiveColor(color);
  };
  return (
    <ul className="color-list">
      {colors.map((color, index) => (
        <li
          className={`color-list__sample color-${color} ${color === currentColor ? 'is-active' : ''}`}
          key={`color-${index}`}
          onClick={() => onColorClick(color)}>
          <button></button>
        </li>
      ))}
    </ul>
  );
}

export default ColorList;
