import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useUpdateEffect } from 'react-use';
import GeneratorSentence from './GeneratorSentence';
import gsap from 'gsap';
import useStore from '../../store/store';
import TokenOverlay from '../Overlays/TokenOverlay';
import SentencesRenderer from '../../components/SentencesRenderer';

// token styles
import { ReactComponent as TokenStyle1 } from '../../assets/images/token-1.svg';
import { ReactComponent as TokenStyle2 } from '../../assets/images/token-2.svg';
import { ReactComponent as TokenStyle3 } from '../../assets/images/token-3.svg';
import { ReactComponent as TokenStyle4 } from '../../assets/images/token-4.svg';
import { ReactComponent as TokenStyle5 } from '../../assets/images/token-5.svg';
import { ReactComponent as TokenStyle6 } from '../../assets/images/token-6.svg';
import tokenAnim1 from '../../assets/animations/token-1.json';
import tokenAnim2 from '../../assets/animations/token-2.json';
import tokenAnim3 from '../../assets/animations/token-3.json';
import tokenAnim4 from '../../assets/animations/token-4.json';
import tokenAnim5 from '../../assets/animations/token-5.json';

import ScrollToPlugin from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);

function GeneratorSentences({
  sentences,
  isPlaying,
  playheadSentenceId,
  curSentenceId,
  onSelect,
  onDelete,
  onAddBefore,
  onReplace,
  autoScroll,
  onAddAfter
}) {
  const tokens = useStore((state) => state.tokens);
  const collectedTokens = useStore((state) => state.collectedTokens);
  const handleCollectToken = useStore((state) => state.collectToken);
  const [revealToken, setRevealToken] = useState(null);
  const [currentToken, setCurrentToken] = useState(null);
  const [isCollectingToken, setIsCollectingToken] = useState(false);

  //
  const prevSentences = useRef(sentences);
  const prevSentencesOrder = useRef(sentences.map((sentence) => sentence.id));
  const [activeSentenceIndex, setActiveSentenceIndex] = useState(null);
  const wrapperRef = useRef(null);

  const [assignedTokens, setAssignedTokens] = useState([]);

  const tokenStyles = [
    tokenAnim1,
    tokenAnim2,
    tokenAnim3,
    tokenAnim4,
    tokenAnim5,
    tokenAnim1,
    tokenAnim2,
    tokenAnim3,
    tokenAnim4,
    tokenAnim5
  ];

  const findMatchingTokens = (text, tokens) => {
    console.log(text);
    if (!text) return;

    // find matching tokens
    let matches = tokens.filter((token) => {
      let hasMatch = false;
      token.matches.forEach((matchStr) => {
        if (text.toLowerCase().includes(matchStr)) {
          hasMatch = true;
        }
      });
      return hasMatch;
    });

    return matches[0] || false;
  };

  // detect new lines so we can attach the eggs
  useEffect(() => {
    if (sentences !== prevSentences.current) {
      // find the difference between the old and the new sentences
      let difference = sentences.filter((x) => !prevSentences.current.includes(x));
      prevSentences.current = sentences;
      if (!difference.length) return;

      // base the available tokens on the placed tokens and the collectedTokens
      let placedTokens = assignedTokens.map((token) => token.tokenId);
      let availableTokens = tokens.filter((token) => ![...collectedTokens, ...placedTokens].includes(token.id));

      // only continue, if the random factor is valid and we haven't reached the max number of tokens
      let luckFactor = Math.random() > 0.5;
      //  let luckFactor = true;

      // console.log(luckFactor);

      if (!luckFactor || placedTokens.length > 3) return;
      // console.log('go');

      let sentence = difference.pop();
      let tokenMatch = findMatchingTokens(sentence.text, availableTokens);
      // let tokenMatch = availableTokens[0];
      if (!tokenMatch) return;

      // set a random tokenStyle
      let tokenStyle = tokenStyles[Math.floor(Math.random() * tokenStyles.length)];
      setAssignedTokens([...assignedTokens, { sentenceId: sentence.id, tokenId: tokenMatch.id, tokenStyle: tokenStyle }]);
    }
  }, [sentences, assignedTokens, collectedTokens, tokens]);

  // detect de index of the active sentence
  useEffect(() => {
    if (curSentenceId !== null) {
      setActiveSentenceIndex(sentences.findIndex((item) => item.id === curSentenceId));
    } else {
      setActiveSentenceIndex(null);
    }
  }, [sentences, curSentenceId]);

  // if the active sentence changes, animate the sentence spacing
  useLayoutEffect(() => {
    let workSpaceBnd = wrapperRef.current.getBoundingClientRect();
    let sentences = Array.from(wrapperRef.current.children);
    let allParts = Array.from(wrapperRef.current.querySelectorAll('.line__part'));

    let afterToolsWidth = 74;
    let preToolsWidth = 40;

    allParts.forEach((part) => {
      gsap.to(part, { x: '0px', duration: 0.2, width: 'auto' });
    });

    // if previous sentence has not enough space for the tools

    // sentence parts on the same line are moved

    allParts.forEach((part) => {
      part.classList.remove('hide-pseudo');
    });

    if (activeSentenceIndex !== null) {
      let activeLineEl = sentences[activeSentenceIndex];
      if (!activeLineEl) return;

      let elControlsBefore = activeLineEl.querySelector('.line-controls--before');
      let controlsBeforeYpos = 0;
      if (elControlsBefore) {
        controlsBeforeYpos = Math.round(elControlsBefore.getBoundingClientRect().top);
      }
      // let elControlsAfter = activeLineEl.querySelector('.line-controls--after');

      let activeLineParts = Array.from(activeLineEl.querySelectorAll('.line__part'));

      if (!activeLineParts.length) return;
      let firstActivePart = activeLineParts[0];
      let lastActivePart = activeLineParts[activeLineParts.length - 1];

      let firstRowY = Math.round(firstActivePart.getBoundingClientRect().top);
      let lastRowY = Math.round(activeLineParts[activeLineParts.length - 1].getBoundingClientRect().top);

      // group sentences by ypos
      let allParts = Array.from(wrapperRef.current.querySelectorAll('.line__part'));

      let sentencePositions = allParts.map((part) => {
        return {
          el: part,
          left: Math.round(part.getBoundingClientRect().left),
          top: Math.round(part.getBoundingClientRect().top)
        };
      });

      // find the last sentence part of each row
      var groupedByTop = sentencePositions.reduce((group, part) => {
        const { top } = part;
        group[top] = group[top] ?? [];
        group[top].push(part);
        return group;
      }, {});

      // handle the rows/parts before -----------------------------------------------------------------------
      let leftOptionsPos = 'before';
      if (Math.abs(firstRowY - controlsBeforeYpos) > 10) {
        leftOptionsPos = 'above';
      }
      var partsBefore = [];
      if (leftOptionsPos === 'before') {
        partsBefore = groupedByTop[firstRowY];
      } else if (leftOptionsPos === 'above') {
        let groupKeys = Object.keys(groupedByTop);
        let groupIndex = groupKeys.indexOf(firstRowY.toString());
        let groupBefore = groupedByTop[groupKeys[groupIndex - 1]];
        partsBefore = [...groupBefore, ...groupedByTop[firstRowY]];
      }

      // remove parts positioned after
      const firstActivePartIndex = partsBefore.findIndex((part) => firstActivePart === part.el);

      // make the beforeparts array smaller so it does not include any active parts or any part thereafter
      partsBefore = partsBefore.slice(0, firstActivePartIndex);

      partsBefore.forEach((part) => {
        gsap.to(part.el, { x: `-${preToolsWidth}px`, duration: 0.2 });
      });

      // if this is an above variant, hide the pseudo temporarily (psuedo is used for filling)
      if (leftOptionsPos === 'above') {
        let lastPartBefore = partsBefore[partsBefore.length - 1];
        if (lastPartBefore) {
          lastPartBefore.el.classList.add('hide-pseudo');
        }
      }

      // handle the rows/parts after ----------------------------------------------------------------------
      var partsAfter = [];
      partsAfter = groupedByTop[lastRowY];

      // remove parts positioned after
      const lastActivePartIndex = partsAfter.findIndex((part) => lastActivePart === part.el);
      partsAfter = partsAfter.slice(lastActivePartIndex + 1);

      partsAfter.forEach((part) => {
        gsap.to(part.el, { x: `${afterToolsWidth}px`, duration: 0.2 });
      });
      // console.log(partsAfter);
      // if (leftOptionsPos === 'before') {
      //   partsBefore = groupedByTop[firstRowY];
      // } else if (leftOptionsPos === 'above') {
      //   let groupKeys = Object.keys(groupedByTop);
      //   let groupIndex = groupKeys.indexOf(firstRowY.toString());
      //   let groupBefore = groupedByTop[groupKeys[groupIndex - 1]];
      //   partsBefore = [...groupBefore, ...groupedByTop[firstRowY]];
      // }

      // partsBefore = partsBefore.filter(part => )

      // remove the active parts
      // partsBefore = partsBefore.filter((part) => !activeLineParts.includes(part.el));
      // console.log(partsBefore);

      // console.log(groupedByTop);
      // console.log(firstRowY);

      // let groupedRows = sentences

      //      // find the last sentence part of each row
      //      var groupedByTop = sentences.reduce((group, part) => {
      //       const { top } = part;
      //       group[top] = group[top] ?? [];
      //       group[top].push(part);
      //       return group;
      //     }, {});

      // let moveLineAbove true;
      // let moveBefore = true;

      // sentences.forEach((line, lineIndex) => {
      //   if (lineIndex === activeSentenceIndex) return;

      //   let lineParts = Array.from(line.querySelectorAll('.line__part'));

      //   lineParts.forEach((linePart) => {
      //     // handle the lines before the active line
      //     if (lineIndex < activeSentenceIndex) {
      //       if (leftOptionsPos === 'before') {
      //         if (linePart.getBoundingClientRect().top === firstRowY) {
      //           gsap.to(linePart, { x: `-${preToolsWidth}px`, duration: 0.2 });
      //         }
      //       } else if (leftOptionsPos === 'above') {
      //       }
      //     }

      //     if (lineIndex > activeSentenceIndex) {
      //       if (linePart.getBoundingClientRect().top === lastRowY) {
      //         gsap.to(linePart, { x: `${afterToolsWidth}px`, duration: 0.2 });
      //       }
      //     }
      //   });
      // });

      // if the first line is active make some space in front
      let activeFirstPart = activeLineParts[0];

      if (activeSentenceIndex === 0) {
        let elControlsBefore = activeLineEl.querySelector('.line-controls--before');
        let firstPartBnd = activeFirstPart.getBoundingClientRect();
        gsap.to(elControlsBefore, { marginLeft: preToolsWidth, duration: 0.2, delay: 0.1 });
        gsap.to(activeFirstPart, { width: firstPartBnd.width - preToolsWidth, duration: 0.2 });
      }

      // console.log(activeLineParts);

      // if the end of the active line almost touches the edge, create some space for the buttons
      let activeLastPart = activeLineParts[activeLineParts.length - 1];
      let lastPartBnd = activeLastPart.getBoundingClientRect();

      let totalToolsWidth = afterToolsWidth;
      // if only one line also substract the pretoolswidth
      if (activeLineParts.length === 1) {
        totalToolsWidth = totalToolsWidth + preToolsWidth;
      }

      // console.log(workSpaceBnd);
      // console.log(window.innerWidth);

      if (lastPartBnd.left + lastPartBnd.width + totalToolsWidth > workSpaceBnd.width) {
        gsap.to(activeLastPart, {
          width: lastPartBnd.width - totalToolsWidth,
          duration: 0.2
        });
      }
    }
  }, [activeSentenceIndex, wrapperRef, sentences]);

  const scrollSentenceIntoView = (id, force = false, center = false) => {
    const sentenceEls = Array.from(wrapperRef.current.querySelectorAll('.line__wrapper')).map((item) => {
      return item.lastChild;
    });
    const parentNode = wrapperRef.current.parentNode;
    const sentenceIndex = sentences.findIndex((sentence) => {
      return sentence.id === id;
    });

    const sentenceEl = sentenceEls[sentenceIndex];
    if (!sentenceEl) return;
    // const activeSentenceEl = sentenceEls[activeSentenceIndex];
    // if (!activeSentenceEl) return;
    const parentNodeBound = parentNode.getBoundingClientRect();
    const sentenceY = sentenceEl.offsetTop;
    // console.log(parentNode);
    // console.log(sentenceY);
    // console.log(parentNode.scrollTop);
    // console.log(parentNodeBound.height);
    // console.log(`${sentenceY - parentNode.scrollTop} < ${parentNodeBound.height}`);
    // if (!force && sentenceY - parentNode.scrollTop < parentNodeBound.height) {
    //   return false;
    // }
    var scrollOffset = parentNodeBound.height - 50;

    if (center) {
      scrollOffset = parentNodeBound.height / 3;
    }
    var scrollPros = sentenceY - scrollOffset;
    var scrolledPos = parentNode.scrollTop;

    // // get difference between two value
    var diff = Math.abs(scrollPros - scrolledPos);
    if (diff > 10) {
      gsap.to(parentNode, { duration: 0.3, scrollTo: scrollPros });
    }
  };

  // keep track of changes in the sentences order
  useEffect(() => {
    const sentencesOrder = sentences.map((sentence) => sentence.id);
    prevSentencesOrder.current = sentencesOrder;
  }, [sentences]);

  // scroll to active sentence into view ---------------------------------------------------------------
  useLayoutEffect(() => {
    if (activeSentenceIndex !== null && autoScroll) {
      let theSentence = sentences[activeSentenceIndex];
      if (!theSentence) return;
      scrollSentenceIntoView(theSentence.id, false, true);

      // const sentenceEls = Array.from(wrapperRef.current.querySelectorAll('.line__wrapper')).map((item) => {
      //   return item.firstChild;
      // });
      // const parentNode = wrapperRef.current.parentNode;
      // const activeSentenceEl = sentenceEls[activeSentenceIndex];
      // if (!activeSentenceEl) return;
      // const parentNodeBound = parentNode.getBoundingClientRect();
      // const activeSentenceY = activeSentenceEl.offsetTop;
      // const scrollOffset = parentNodeBound.height / 3;

      // var scrollPros = activeSentenceY - scrollOffset;
      // var scrolledPos = parentNode.scrollTop;

      // // get difference between two value
      // var diff = Math.abs(scrollPros - scrolledPos);
      // if (diff > 10) {
      //   gsap.to(parentNode, { duration: 0.3, scrollTo: scrollPros });
      // }
    }
  }, [activeSentenceIndex, autoScroll, sentences]);

  // collect the token
  const collectToken = (tokenId) => {
    let tokenIsCollected = collectedTokens.includes(tokenId);
    // console.log(tokenIsCollected);
    if (!tokenIsCollected) {
      setIsCollectingToken(true);
      handleCollectToken(tokenId);
    } else {
      setIsCollectingToken(false);
    }

    setCurrentToken(tokenId);
    setRevealToken(true);
  };

  const closeTokenOverlay = () => {
    setRevealToken(false);
    setTimeout(() => {
      setIsCollectingToken(false);
    }, 500);
  };

  // playhead thingy
  useUpdateEffect(() => {
    if (!wrapperRef.current) return;
    //wrapperRef.current =
    let line = wrapperRef.current.querySelector(`[data-sentence-id="${playheadSentenceId}"]`);

    if (!line) return;
    let parts = line.querySelectorAll('.line__part');
    gsap.set(parts, { filter: `grayscale(0)`, zIndex: 10 });
    gsap.to(parts, { opacity: 1, duration: 0.5 });

    // navigate to the sentence

    const parentNode = wrapperRef.current.parentNode;
    const activeSentenceEl = parts[0];

    if (!activeSentenceEl) return;

    const parentNodeBound = parentNode.getBoundingClientRect();
    const activeSentenceY = activeSentenceEl.offsetTop;
    const scrollOffset = parentNodeBound.height / 3;

    var scrollPros = activeSentenceY - scrollOffset;
    var scrolledPos = parentNode.scrollTop;

    // get difference between two value
    var diff = Math.abs(scrollPros - scrolledPos);
    if (diff > 10) {
      gsap.to(parentNode, { duration: 0.3, scrollTo: scrollPros });
    }
    // console.log(layer);
  }, [playheadSentenceId, wrapperRef.current]);

  useEffect(() => {
    if (!wrapperRef.current) return;
    let lines = Array.from(wrapperRef.current.querySelectorAll(`[data-sentence-id]`));

    if (isPlaying) {
      lines.forEach((layer) => {
        let parts = layer.querySelectorAll('.line__part');
        gsap.set(parts, { opacity: 0.2, zIndex: 0, filter: `grayscale(1)` });
      });
    } else {
      lines.forEach((layer) => {
        let parts = layer.querySelectorAll('.line__part');

        gsap.set(parts, { opacity: 1, zIndex: 0, filter: `grayscale(0)` });
      });
      const parentNode = wrapperRef.current.parentNode;
      gsap.to(parentNode, { duration: 0.3, scrollTo: 0 });
    }
  }, [isPlaying, wrapperRef.current]);

  // scroll to newly added sentence
  useLayoutEffect(() => {
    if (!autoScroll) return;
    var newSentence = false;

    sentences.forEach((sentence) => {
      if (prevSentencesOrder.current.indexOf(sentence.id) === -1) {
        newSentence = sentence;
      }
    });

    if (!newSentence) return;

    // added a timeout because otherwise the element wasn't added yet
    setTimeout(() => {
      scrollSentenceIntoView(newSentence.id);
    }, 50);
  }, [sentences, autoScroll, prevSentencesOrder]);

  // fill the gaps for al sentences which do not reach till the end of the screen
  useLayoutEffect(() => {
    const wrapperWidth = wrapperRef.current.clientWidth;
    const wrapperOffset = wrapperRef.current.getBoundingClientRect().left;
    // console.log(wrapperRef.current.clientWidth);
    if (sentences.length) {
      setTimeout(() => {
        let allParts = Array.from(wrapperRef.current.querySelectorAll('.line__part'));

        gsap.set(allParts, { clearProps: 'width' });
        let sentencePositions = allParts.map((part) => {
          return { el: part, top: Math.round(part.getBoundingClientRect().top) };
        });

        if (!sentencePositions.length) return;

        // find the last sentence part of each row
        var groupedByTop = sentencePositions.reduce((group, part) => {
          const { top } = part;
          group[top] = group[top] ?? [];
          group[top].push(part);
          return group;
        }, {});

        var lastParts = Object.entries(groupedByTop)
          .slice(0, -1) // remove last line, to prevent the last line reaching till the end
          .map(([top, parts]) => {
            let part = parts.pop();
            let bound = part.el.getBoundingClientRect();
            // console.log(part);
            // console.log(bound);
            // console.log(wrapperWidth);
            let newWidth = 0;

            if (bound.x - wrapperOffset + bound.width < wrapperWidth) {
              newWidth = wrapperWidth - bound.x;
            }

            // console.log(newWidth);
            return {
              el: part.el,
              top: part.top,
              currentWidth: bound.width,
              newWidth: newWidth - 0.5
            };
          })
          .filter((part) => part.newWidth > 0);

        lastParts.forEach((part) => {
          let computedStyles = getComputedStyle(part.el);
          let paddingRight = parseInt(computedStyles.paddingRight);

          // part.el.classList.add('last');
          // console.log(part.el);
          // console.log(
          //   `curWidth:${part.currentWidth} newWidth:${part.newWidth} = ${part.newWidth - part.currentWidth + paddingRight}`
          // );
          gsap.set(part.el, { paddingRight: part.newWidth - part.currentWidth + paddingRight });
          part.el.classList.add('extend');

          // gsap.set(part.el, { width: part.newWidth - 5 });
        });

        // create
      }, 150);
    }
  }, [sentences, wrapperRef]);

  const sentencesOrder = sentences.map((sentence) => sentence.id);
  return (
    <>
      <div className="generator-worklines" ref={wrapperRef}>
        {sentences.map((sentence, index) => {
          const hasToken = assignedTokens.find((token) => token.sentenceId === sentence.id);
          var tokenIsCollected = false;
          if (hasToken) {
            tokenIsCollected = collectedTokens.includes(hasToken.tokenId);
          }

          // const matchingToken = activeSentenceIndex === index;
          // let hasMatch = findMatchingTokens(sentence.text, tokens);
          // if (hasMatch) {
          //   addToken(hasMatch);
          // }
          // console.log('current order');
          // console.log(sentencesOrder);

          // console.log('prev order');
          // console.log(prevSentencesOrder.current);

          // check if sentences order has changed
          // const hasChanged = sentencesOrder[index] !== prevSentencesOrder.current[index];

          // check if this is a new sentence, if there were no sentences before, this is completely new
          // and we dont want the animation
          var isNewSentence = false;
          if (prevSentencesOrder.current.length > 0) {
            isNewSentence = prevSentencesOrder.current.indexOf(sentence.id) === -1;
          }

          return (
            <GeneratorSentence
              key={`line-${index}-${sentence.id}`}
              text={sentence.text}
              sentenceId={sentence.id}
              animate={isNewSentence}
              sentencePosition={index}
              isActive={sentence.id === curSentenceId}
              isNew={isNewSentence}
              token={hasToken ? hasToken : null}
              tokenCollected={tokenIsCollected}
              onTokenCollect={collectToken}
              onSelect={() => onSelect(sentence.id)}
              onReplace={() => onReplace(sentence.id)}
              onDelete={() => onDelete(sentence.id)}
              onAddBefore={(type) => onAddBefore(sentence.id, type)}
              onAddAfter={(type) => onAddAfter(sentence.id, type)}></GeneratorSentence>
          );
        })}
      </div>
      {/* <div>{revealToken}</div> */}

      <SentencesRenderer sentences={sentences}></SentencesRenderer>
      <TokenOverlay
        visible={revealToken}
        tokenIsCollecting={isCollectingToken}
        tokenCollected={collectedTokens.includes(currentToken)}
        tokenId={currentToken}
        onClose={closeTokenOverlay}></TokenOverlay>
    </>
  );
}

export default GeneratorSentences;
