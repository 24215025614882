//https://blog.logrocket.com/build-modal-with-react-portals/
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import Overlay from '../Overlay';
import { Transition } from 'react-transition-group';
import { useRef } from 'react';
import gsap from 'gsap';
import useWorkStore from '../../store/workStore';
import useStore from '../../store/store';

import ColorList from '../ColorList';

function PageHelp({ onContentSelect, visible, onClose, tokenId }) {
  const nodeRef = useRef(null);
  const tokens = useStore((state) => state.tokens);
  const [selectedColor, setSelectedColor] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState(false);
  const [noAnimation, setNoAnimation] = useState(true);

  const colors = ['brown', 'purple', 'blue', 'orange', 'red'];

  // find one token by id
  const token = tokens.find((token) => token.id === tokenId);
  // console.log(token);
  // reset
  useEffect(() => {}, [setSelectedMethod]);

  // useLayoutEffect(() => {
  //   setWorkColor(selectedColor);
  // }, [selectedColor, setWorkColor]);

  // const onCloseOverlay = () => {
  //   setNoAnimation(false);
  //   setVisible(false);
  // };

  //   const onEnter = () => {
  //     // console.log('aaa');
  //     gsap.fromTo(nodeRef.current, { x: '100%' }, { duration: 0.2, x: '0%' });
  //   };
  //   const onExit = () => {};

  // console.log('visible');
  // console.log(visible);
  return (
    <Overlay isVisible={visible} overlayType={'panel'} handleClose={onClose}>
      <div>
        <h1>Help</h1>
      </div>
    </Overlay>
  );
}

export default PageHelp;
