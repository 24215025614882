import { useEffect, useRef, useState } from 'react';
import useDialogStore from '../store/dialogStore';

export function useDialogConfirm() {
  const promiseRef = useRef(null);
  const testRef = useRef(null);
  // this active thing is used to prevent multiple dialogs from responding to the same answer
  const [isActive, setIsActive] = useState(false);
  const setVisible = useDialogStore((state) => state.setVisible);
  const setTitle = useDialogStore((state) => state.setTitle);
  const answer = useDialogStore((state) => state.answer);
  const reset = useDialogStore((state) => state.reset);
  const setAnswer = useDialogStore((state) => state.setAnswer);
  const setText = useDialogStore((state) => state.setText);

  // const [confirm, setConfirm] = useContext(ConfirmContext);
  const [hasAnswered, setHasAnswered] = useState(false);

  // listen for the answer, when answered continue with the promise
  // and hide the dialog
  useEffect(() => {
    if (!isActive) return;
    if (answer === null) return;
    if (hasAnswered) return;

    if (answer) {
      promiseRef.current.resolve();
    } else {
      promiseRef.current.reject();
    }

    setVisible(false);
    setIsActive(false);
    setHasAnswered(true);
  }, [isActive, answer, hasAnswered, promiseRef, setVisible]);

  const isConfirmed = (data) => {
    var promise = new Promise((resolve, reject) => {
      reset();
      setText(data.title, data.textCancel, data.textProceed);
      setVisible(true);
      setHasAnswered(false);
      setIsActive(true);

      promiseRef.current = { resolve, reject };
    });
    return promise.then(
      () => {
        return true;
      },
      () => {
        return false;
      }
    );
  };
  //     () => {
  //       setConfirm({ ...confirm, isOpen: false });
  //       return true;
  //     },
  //     () => {
  //       setConfirm({ ...confirm, isOpen: false });
  //       return false;
  //     }
  //   );

  return {
    isConfirmed
  };

  // const isConfirmed = (prompt) => {
  //   const promise = new Promise((resolve, reject) => {
  //     setConfirm({
  //       prompt,
  //       isOpen: true,
  //       proceed: resolve,
  //       cancel: reject
  //     });
  //   });
  //   return promise.then(
  //     () => {
  //       setConfirm({ ...confirm, isOpen: false });
  //       return true;
  //     },
  //     () => {
  //       setConfirm({ ...confirm, isOpen: false });
  //       return false;
  //     }
  //   );
  // };
  // return {
  //   ...confirm,
  //   isConfirmed
  // };
}
