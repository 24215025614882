export const prepareIllustrationObj = (illustration) => {
  return { id: illustration.id, ...illustration.image };
};

// -- Illustrations ------------------------------------------------------------------------------- //
export const getMatchingIllustration = (illustrations, sentence, usedIllustrationsIds, layer = []) => {
  let items = getMatchingIllustrationByCat(illustrations, sentence.categories, usedIllustrationsIds, layer);
  // !! add something to filter on matches
  // let matchGroups = items.reduce((acc, item) => {
  //   if (acc[item.matches]) {
  //     acc[item.matches].push(item);
  //   } else {
  //     acc[item.matches] = [item];
  //   }
  //   return acc;
  // }, {});

  // shuffle the items
  items = items.sort(() => Math.random() - 0.5);

  // sort based on matches
  items = items.sort((a, b) => b.matches - a.matches);

  // pick a random illustration
  let randomIndex = Math.floor(Math.random() * items.length);
  let randomIllustration = items[randomIndex];
  if (!randomIllustration) {
    console.warn('no matching illustration');
    return false;
  }
  return randomIllustration.illustration;
};

export const getMatchingIllustrationByCat = (items, cats, excl = [], allowedLayers = []) => {
  // let matchedIllustrations = items
  //   .map((item) => {
  //     let notAlreadyUsed = excl.indexOf(item.id) === -1;
  //     // check if it matches the requested layer type
  //     let matchesLayerType = true;
  //     if (allowedLayers.length > 0) {
  //       matchesLayerType = item.image.layer.filter((layer) => allowedLayers.includes(layer));
  //       matchesLayerType = matchesLayerType.length > 0;
  //     }

  //     //
  //     let intersect = item.categories.filter((cat) => cats.includes(cat));
  //     if (intersect.length && notAlreadyUsed && matchesLayerType) {
  //       return {
  //         matches: intersect.length,
  //         illustration: prepareIllustrationObj(item)
  //       };
  //     }
  //     return false;
  //   })
  //   .filter((item) => item);

  let returnMatches = [];

  let matchedIllustrations = items
    .map((item) => {
      let notAlreadyUsed = excl.indexOf(item.id) === -1;
      // check if it matches the requested layer type

      //
      let intersect = item.categories.filter((cat) => cats.includes(cat));
      if (intersect.length && notAlreadyUsed) {
        return {
          matches: intersect.length,
          layer: item
        };
      }
      return false;
    })
    .filter((item) => item);

  //
  // check if there are matches which also obey to the layer type
  let restrictedMatches = matchedIllustrations
    .map((item) => {
      let matchesLayerType = true;
      if (allowedLayers.length > 0) {
        matchesLayerType = item.layer.image.layer.filter((layer) => allowedLayers.includes(layer));
        matchesLayerType = matchesLayerType.length > 0;
      }
      if (matchesLayerType) {
        return item;
      }
      return false;
    })
    .filter((item) => item);

  returnMatches = matchedIllustrations;

  if (restrictedMatches.length) {
    returnMatches = restrictedMatches;
  } else {
  }

  returnMatches = returnMatches.map((item) => {
    return {
      matches: item.matches,
      illustration: prepareIllustrationObj(item.layer)
    };
  });
  // matchedIllustrations = matchedIllustrations.map(() => {});

  return returnMatches;
  // find illustrations which match the categories and exclude the ones in the excl array
  // let matchedIllustrations = items.filter((item) => {
  //   let notAlreadyUsed = excl.indexOf(item.id) === -1;
  //   let intersect = item.categories.filter((cat) => cats.includes(cat));
  //   return intersect.length && notAlreadyUsed;
  // });
};

// -- Sentences ----------------------------------------------------------------------------------- //

export const getMatchingSentencesByCat = (items, cats, excl = []) => {
  let matchedSentences = items
    .map((item) => {
      let notAlreadyUsed = excl.indexOf(item.id) === -1;
      let intersect = item.categories.filter((cat) => cats.includes(cat));
      if (intersect.length && notAlreadyUsed) {
        return {
          matches: intersect.length,
          sentence: item
        };
      }
      return false;
    })
    .filter((item) => item);

  return matchedSentences;
};

export const getMatchingSentence = (sentences, illustration, usedSentencesIds) => {
  let items = getMatchingSentencesByCat(sentences, illustration.categories, usedSentencesIds);

  items = items.sort(() => Math.random() - 0.5);
  items = items.sort((a, b) => b.matches - a.matches);
  // pick a random sentence
  let randomIndex = Math.floor(Math.random() * items.length);
  let randomItem = items[randomIndex];

  return randomItem.sentence;
};
