import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useUpdateEffect, useKeyPressEvent } from 'react-use';
import React, { forwardRef } from 'react';
import CompositionDrawer from '../../common/CompositionDrawer';
import CompositionDrawerHtml from '../../common/CompositionDrawerHtml';
import gsap from 'gsap';
import IconButton from '../../components/IconButton';
import { ReactComponent as IconScale } from '../../assets/icons/icon-scale.svg';
import { ReactComponent as IconPosition } from '../../assets/icons/icon-position.svg';
import { ReactComponent as IconConfirm } from '../../assets/icons/icon-confirm.svg';

import { ReactComponent as IconImageZoomIn } from '../../assets/icons/icon-img-zoomin.svg';
import { ReactComponent as IconImageZoomOut } from '../../assets/icons/icon-img-zoomout.svg';
import { ReactComponent as IconImageArrow } from '../../assets/icons/icon-img-arrow.svg';

import { Provider as BusProvider, useBus, useListener } from 'react-bus';

function IllustrationInterface({
  layers,
  playheadIllustrationId,
  curIllustrationId,
  isActive,
  gridSize,
  changePos,
  changeScale,
  onClose
}) {
  const layer = layers.find((layer) => layer.id === curIllustrationId);
  const [controlMethod, setControlMethod] = useState('position');
  if (!layer) return;

  const cellWidth = 100 / gridSize;

  const styles = {
    width: layer.positioning.xSize * cellWidth + '%',
    height: layer.positioning.ySize * cellWidth + '%',
    top: layer.positioning.yPos * cellWidth + '%',
    left: layer.positioning.xPos * cellWidth + '%'
  };

  let availableMutiations = {
    left: false,
    right: false,
    up: false,
    down: false,
    plus: false,
    min: false
  };

  const pos = layer.positioning;

  if (controlMethod === 'position') {
    if (pos.xSize < gridSize) {
      if (pos.xPos > 0) {
        availableMutiations.left = true;
      }
      if (pos.xPos + pos.xSize < gridSize) {
        availableMutiations.right = true;
      }
    }

    if (pos.ySize < gridSize) {
      if (pos.yPos > 0) {
        availableMutiations.up = true;
      }
      if (pos.yPos + pos.ySize < gridSize) {
        availableMutiations.down = true;
      }
    }
  }

  if (controlMethod === 'scale') {
    if (pos.xSize < gridSize && pos.ySize < gridSize) {
      availableMutiations.plus = true;
    }
    if (pos.xSize > 1 && pos.ySize > 1) {
      availableMutiations.min = true;
    }
  }

  const onChangePos = (layerId, axis, value) => {
    changePos(layerId, axis, value);
  };

  const onChangeScale = (layer, value) => {
    // check if we also need to adjust the position
    // if (value > 0) {

    // }
    // if (value < 0) {

    // }
    changeScale(layer, value);
  };
  // if (pos.xSize === gridSize && pos.ySize === gridSize) {
  //   availableMutiations = { left: false, right: false, up: false, down: false };
  // } else if () {

  // }

  // >{'←'}
  // console.log(layer);
  // console.log(curIllustrationId);
  // console.log(layers);
  // console.log(layer);
  return (
    layer &&
    isActive && (
      <div className="illustration-editor">
        <div className="illustration-editor__controls">
          <div>
            <IconButton
              className={controlMethod === 'scale' ? 'is-active' : ''}
              Icon={IconScale}
              onClick={() => setControlMethod('scale')}
            />
          </div>
          <div>
            <IconButton
              className={controlMethod === 'position' ? 'is-active' : ''}
              Icon={IconPosition}
              onClick={() => setControlMethod('position')}
            />
          </div>
          <div>
            <IconButton Icon={IconConfirm} onClick={() => onClose()} />
          </div>

          {/* <button onClick={() => setControlMethod('position')}>pos</button>
          <button onClick={() => setControlMethod('scale')}>scale</button> */}
        </div>
        <div className="illustration-editor__layer" style={styles}>
          <div className="illustration-editor__controller">
            {availableMutiations.up && (
              <button className="control-up" onClick={() => changePos(layer.id, 'y', -1)}>
                <IconImageArrow />
              </button>
            )}
            {availableMutiations.down && (
              <button className="control-down" onClick={() => changePos(layer.id, 'y', 1)}>
                <IconImageArrow />
              </button>
            )}
            {availableMutiations.left && (
              <button className="control-left" onClick={() => changePos(layer.id, 'x', -1)}>
                <IconImageArrow />
              </button>
            )}
            {availableMutiations.right && (
              <button className="control-right" onClick={() => changePos(layer.id, 'x', 1)}>
                <IconImageArrow />
              </button>
            )}
            {availableMutiations.min && (
              <button className="control-min" onClick={() => changeScale(layer.id, -1)}>
                <IconImageZoomOut />
              </button>
            )}
            {availableMutiations.plus && (
              <button className="control-plus" onClick={() => changeScale(layer.id, 1)}>
                <IconImageZoomIn />
              </button>
            )}
          </div>
        </div>
      </div>
    )
  );
}

function HtmlLayer({ layer, gridSize, isDisabled }) {
  const [loaded, setLoaded] = useState(false);
  let ref = useRef(null);
  let cellWidth = 100 / gridSize;
  let cellHeight = 100 / gridSize;
  let cellSize = 100 / gridSize;
  let xPos = layer.xPos * cellWidth;
  let yPos = layer.yPos * cellWidth;

  let renderWidth = cellSize * layer.xSize;
  let renderHeight = renderWidth * layer.aspect;

  // when the image is destined for the bottom of the screen
  // make sure the image aligns to the bottom
  if (layer.yPos === gridSize - 1) {
    let diff = cellSize - renderHeight;
    yPos = layer.yPos * cellSize + diff;
  }

  // useEffect(() => {
  //   console.log(layer);
  //   console.log('position changed');
  // }, [layer.xPos, layer.yPos, layer.xSize, layer.ySize]);

  useLayoutEffect(() => {
    if (!ref.current) return;
    gsap.set(ref.current, { opacity: 0 });
  }, [ref]);
  useEffect(() => {
    if (loaded) return;

    const preloadImage = new Image();
    preloadImage.onload = setLoaded(true);
    preloadImage.src = layer.url;
  }, [loaded]);

  useLayoutEffect(() => {
    gsap.fromTo(ref.current, { opacity: 0, y: 20 }, { opacity: 1, y: 0, duration: 0.5 });
  }, [loaded]);

  useUpdateEffect(() => {
    if (!loaded) return;
    if (isDisabled) {
      gsap.set(ref.current, { clearProps: 'zIndex', filter: `grayscale(1)` });
      gsap.fromTo(ref.current, { filter: `grayscale(0)`, opacity: 1 }, { filter: `grayscale(1)`, opacity: 0.3, duration: 0.5 });
      // gsap.fromTo(ref.current, { zIndex: 0, opacity: 0 }, { zIndex: 1, opacity: 1, duration: 0.2 });
    } else {
      // gsap.set(ref.current, { opacity: 1, zIndex: 1 });
      gsap.fromTo(ref.current, { zIndex: 1, filter: `grayscale(0)` }, { opacity: 1, duration: 0.5 });
      // gsap.set(ref.current, { zIndex: 'initial' });
    }
    // gsap.fromTo(
    //   ref.current,
    //   { filter: `grayscale(${isDisabled ? 0 : 1})`, duration: 0.5 },
    //   { filter: `grayscale(${isDisabled ? 100 : 0})`, duration: 0.5 }
    // );
  }, [isDisabled, loaded]);

  const styles = {
    position: 'absolute',
    // border: '1px solid black',
    width: layer.xSize * cellWidth + '%',
    top: yPos + '%',
    left: xPos + '%',
    aspectRatio: `${layer.imgWidth}/${layer.imgHeight}`
  };
  return (
    <div className="html-layer" style={styles} ref={ref} data-layer-id={layer.id}>
      <img src={layer.url} alt=""></img>
    </div>
  );
}

function HtmlRenderer({ playheadIllustrationId, isPlaying, gridSize, curIllustrationId, layers, onClick }) {
  const wrapperRef = useRef(null);
  const [htmlLayers, setHtmlLayers] = useState([]);

  // useUpdateEffect(() => {
  //   console.log('aa');
  //   console.log(curIllustrationId);
  // }, [curIllustrationId]);

  useEffect(() => {
    // setLoading(true);

    const simpliefiedLayers = layers.map((layer) => {
      return {
        ...layer.illustration,
        ...layer.positioning
      };
    });
    setHtmlLayers(simpliefiedLayers);

    // let illustrations = illustration.map((layer) => layer.illustration);
  }, [layers]);

  const onLayerLoaded = () => {};

  useUpdateEffect(() => {
    if (!wrapperRef) return;

    //wrapperRef.current =
    let layer = wrapperRef.current.querySelector(`[data-layer-id="${playheadIllustrationId}"]`);
    if (!layer) return;
    gsap.set(layer, { filter: `grayscale(0)`, zIndex: 10, y: 20 });
    gsap.to(layer, { opacity: 1, duration: 1 });
    gsap.to(layer, { y: 0, duration: 3 });

    // console.log(layer);
  }, [playheadIllustrationId, wrapperRef.current]);

  useEffect(() => {
    if (!wrapperRef) return;
    let layers = Array.from(wrapperRef.current.querySelectorAll(`[data-layer-id]`));
    if (isPlaying) {
      layers.forEach((layer) => {
        gsap.set(layer, { opacity: 0.1, zIndex: 0, filter: `grayscale(1)`, y: 20 });
      });
    } else {
      layers.forEach((layer) => {
        gsap.set(layer, { opacity: 1, zIndex: 0, filter: `grayscale(0)` });
      });
    }
  }, [isPlaying, wrapperRef.current]);
  return (
    <div className="html-renderer" onClick={onClick} ref={wrapperRef}>
      {htmlLayers.map((layer) => (
        <HtmlLayer
          key={`html-layer-${layer.id}`}
          layer={layer}
          isPlaying={isPlaying}
          isDisabled={curIllustrationId && curIllustrationId !== layer.id}
          isHighlighted={layer.id === curIllustrationId}
          gridSize={gridSize}
          onLayerLoaded={onLayerLoaded}
        />
      ))}
    </div>
  );
}

// function HtmlRenderer({ gridSize, curIllustrationId, layers }) {
//   const htmlRef = useRef();
//   const compositionDrawerHtml = useRef();
//   const [loading, setLoading] = useState(true);

//   const onLoaded = () => {
//     console.log('loaded!');
//     setLoading(false);
//   };

//   useEffect(() => {
//     compositionDrawerHtml.current = new CompositionDrawer({
//       container: htmlRef.current,
//       gridSize: gridSize,
//       onLoaded: onLoaded
//     });
//   }, []);

//   useUpdateEffect(() => {
//     compositionDrawerHtml.current.highlightLayer(curIllustrationId);
//   }, [curIllustrationId]);

//   useEffect(() => {
//     if (!compositionDrawerHtml.current) return;

//     // setLoading(true);

//     const simpliefiedLayers = layers.map((layer) => {
//       return {
//         ...layer.illustration,
//         ...layer.positioning
//       };
//     });
//     // let illustrations = illustration.map((layer) => layer.illustration);

//     compositionDrawerHtml.current.drawIllustration(simpliefiedLayers);
//   }, [compositionDrawerHtml, layers]);

//   return <div className="html-renderer" ref={htmlRef}></div>;
// }

// -- Generator --------------------------------------------------------
function GeneratorCanvas(
  {
    layers,
    playheadIllustrationId,
    isPlaying,
    curIllustrationId,
    thumbGeneratedCb,
    onPosChangeCb,
    onScaleChangeCb,
    gridSize,
    resetSelection
  },
  ref
) {
  const compositionDrawer = useRef();
  const [debug, setDebug] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editorActive, setEditorActive] = useState(false);

  useKeyPressEvent('F1', () => setDebug(!debug));

  const changePos = (layerId, axis, value) => {
    onPosChangeCb(layerId, axis, value);
  };

  const changeScale = (layerId, value) => {
    onScaleChangeCb(layerId, value);
  };

  const onLoaded = () => {
    setLoading(false);
  };

  useListener('edit-illustration', () => {
    setEditorActive(true);
  });

  useEffect(() => {
    compositionDrawer.current = new CompositionDrawer({
      gridSize: gridSize
    });

    ref.current = compositionDrawer.current;
  }, [ref]);

  useUpdateEffect(() => {
    setEditorActive(false);

    // compositionDrawer.current.highlightLayer(curIllustrationId);
  }, [curIllustrationId]);

  useEffect(() => {
    if (!compositionDrawer.current) return;

    // setLoading(true);

    const simpliefiedLayers = layers.map((layer) => {
      return {
        ...layer.illustration,
        ...layer.positioning
      };
    });
    // let illustrations = illustration.map((layer) => layer.illustration);

    compositionDrawer.current.setIllustration(simpliefiedLayers);

    // compositionDrawer.current.drawIllustration(simpliefiedLayers);
  }, [compositionDrawer, layers]);

  const onCanvasClick = useCallback(() => {
    if (curIllustrationId) {
      setEditorActive(true);
    }
  }, [curIllustrationId, editorActive]);

  return (
    <>
      <HtmlRenderer
        playheadIllustrationId={playheadIllustrationId}
        isPlaying={isPlaying}
        gridSize={gridSize}
        curIllustrationId={curIllustrationId}
        layers={layers}
        onClick={onCanvasClick}
      />

      {debug && (
        <ul className="generator-canvas-debug">
          {layers.map((layer, index) => (
            <li key={`canvas-ill-` + index}>
              <figure>
                <img width={layer.width} height={layer.height} src={layer.illustration.url} alt="" />
              </figure>
              <div>
                <div>{`id(${layer.illustration.id}) x:${layer.positioning.xPos.toFixed(2)} y:${layer.positioning.yPos.toFixed(
                  2
                )} w:${layer.positioning.xSize} h:${layer.positioning.ySize}`}</div>
                <div>
                  <button onClick={() => changePos(layer.id, 'x', -1)}>{'←'}</button>
                  <button onClick={() => changePos(layer.id, 'x', 1)}>{'→'}</button>

                  <button onClick={() => changePos(layer.id, 'y', -1)}>{'↑'}</button>
                  <button onClick={() => changePos(layer.id, 'y', 1)}>{'↓'}</button>

                  <button onClick={() => changeScale(layer.id, -1)}>{'-'}</button>
                  <button onClick={() => changeScale(layer.id, 1)}>{'+'}</button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
      <IllustrationInterface
        gridSize={gridSize}
        layers={layers}
        isActive={editorActive}
        curIllustrationId={curIllustrationId}
        changePos={changePos}
        changeScale={changeScale}
        onClose={resetSelection}
      />
      {/* <div className={`canvas-loader ${loading ? 'is-visible' : ''}`}> */}

      <div className={`canvas-loader `}>
        <span className="loader__spinner"></span>
      </div>
    </>
  );
}

export default forwardRef(GeneratorCanvas);
