import { useState, useCallback, useRef, useEffect } from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { PopupMenu } from './Popup/PopupMenu';
import { useBus } from 'react-bus';

import { ReactComponent as IconHouse } from '../assets/icons/icon-house.svg';
import { ReactComponent as IconAddLarge } from '../assets/icons/icon-add.svg';

import { ReactComponent as IconLargePlus } from '../assets/icons/icon-large-plus.svg';
import { ReactComponent as IconLargeNotebook } from '../assets/icons/icon-large-notebook.svg';

// MainButton --------------------------------------------------
function MainButton() {
  const state = useState();
  const menuTriggerRef = useRef(null);
  const location = useLocation();
  const bus = useBus();
  const navigate = useNavigate();

  // returns the menu options based on the current page
  const getMenuOptions = useCallback(() => {
    const allOptions = [
      {
        pathName: '/generator',
        options: [
          { label: 'Zin', slug: 'add_sentence' },
          { label: 'Illustratie', slug: 'add_illustration' },
          { label: 'Vers werk', slug: 'reset' }
        ]
      },
      {
        pathName: '/dashboard',
        options: [
          { label: 'Werk genereren', slug: 'generate_poem' },
          { label: 'Werk opstellen', slug: 'compose_poem' }
        ]
      }
    ];

    if (location.pathname === '/') return [];

    // if (!location.pathname) return [];
    // get all options from allOptions where pathName === location.pathname
    const options = allOptions.filter((option) => option.pathName === location.pathname)[0].options || [];
    return options;
  }, [location.pathname]);

  const onMenuClick = (option) => {
    bus.emit(option.slug);

    // dirty way, but works for now
    if (option.slug === 'compose_poem') {
      navigate('/generator', { state: { showWizard: true } });
      // setTimeout(() => {
      //   bus.emit('add_sentence');
      // }, 0);
    }

    if (option.slug === 'generate_poem') {
      navigate('/generator', { state: { showGenerator: true } });
      // setTimeout(() => {
      //   bus.emit(option.slug);
      // }, 0);
    }
  };

  //   const onClick = () => {
  //     setShowMenu(!showMenu);
  //   };

  const menuOptions = getMenuOptions();

  return (
    <div className="main-button">
      <PopupMenu options={menuOptions} onMenuClick={onMenuClick}>
        <button className="toolbar-button">
          <IconLargePlus />
        </button>
      </PopupMenu>
    </div>
  );
}

// Toolbar --------------------------------------------------
function Toolbar() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="toolbar">
      <div>
        <button
          className={`toolbar-button ${location.pathname === '/dashboard' ? 'is-active' : ''}`}
          onClick={() => navigate('/dashboard')}>
          <IconLargeNotebook />
        </button>
      </div>

      <div>
        <MainButton />
      </div>
      <div></div>
    </div>
  );
}

export default Toolbar;
