// https://codesandbox.io/s/dialogprovider-bz1j1?file=/src/DialogProvider.tsx:1122-1366
import React, { useContext, useEffect, useRef, useState } from 'react';
import DialogOverlay from './DialogOverlay';

const DialogContext = React.createContext([() => {}, () => {}]);

function DialogConfirm({ open, text, disableCancel, textCancel, textProceed, onConfirm, onCancel, onClose }) {
  const onAnswer = (answer) => {
    if (answer) {
      if (onConfirm) onConfirm();
    } else {
      if (onCancel) onCancel();
    }
    onClose();
  };
  return (
    <>
      {open && (
        <div className="dialog-wrapper">
          <div className="dialog-wrapper__backdrop"></div>
          <div className="dialog">
            <div className="dialog__wrapper">
              <header className="dialog__header"></header>
              <div className="dialog__content">{text}</div>
              <footer className="dialog__footer">
                <button className="pill-button is-primary" onClick={() => onAnswer(true)}>
                  {textProceed}
                </button>
                {!disableCancel && (
                  <button className="pill-button" onClick={() => onAnswer(false)}>
                    {textCancel}
                  </button>
                )}
              </footer>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default function DialogProvider({ children }) {
  const [dialogs, setDialogs] = useState([]);

  const createConfirmDialog = (data) => {
    const dialog = {
      type: data.type,
      text: data.text,
      onConfirm: data.onConfirm || false,
      onCancel: data.onCancel || false,
      textCancel: data.textCancel || 'nee',
      textProceed: data.textProceed || 'ok',
      disableCancel: data.disableCancel || false,
      open: true
    };

    return dialog;
  };

  const createDialog = (data) => {
    var dialog;
    if (data.type === 'confirm') {
      dialog = createConfirmDialog(data);
    }

    setDialogs((dialogs) => [...dialogs, dialog]);
  };
  const closeDialog = () => {
    setDialogs((dialogs) => {
      const latestDialog = dialogs.pop();
      if (!latestDialog) return dialogs;
      if (latestDialog.onClose) latestDialog.onClose();
      return [...dialogs].concat({ ...latestDialog, open: false });
    });
  };
  const contextValue = useRef([createDialog, closeDialog]);

  // const contextValue = React.useRef([createDialog, closeDialog] as const);

  return (
    <>
      <DialogContext.Provider value={contextValue.current}>
        {children}
        {dialogs.map((dialog, i) => {
          return (
            <DialogOverlay key={`key-${i}`}>
              <DialogConfirm {...dialog} onClose={closeDialog} />
              {/* <div className="dialog-wrapper">
                <div className="dialog">
                  dialog <button onClick={() => dialog.callback()}>close</button>
                </div>
              </div> */}
            </DialogOverlay>
          );
        })}
      </DialogContext.Provider>
    </>
  );
}

export const useDialog = () => useContext(DialogContext);
