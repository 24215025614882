import { useQuery } from '@apollo/client';

import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useStore from '../store/store';
import loaderAnimation from '../assets/animations/loader-lottie.json';
import Lottie from 'react-lottie-player';

import { GET_LINES, GET_ILLUSTRATIONS, GET_CAT_THEMES, GET_CAT_COMPOSER, GET_TOKENS } from '../common/Queries';

function Loader() {
  const navigate = useNavigate();

  const isNotFirstUse = useStore((state) => state.isNotFirstUse);

  const setTokens = useStore((state) => state.setTokens);
  const setIllustrations = useStore((state) => state.setIllustrations);
  const setSentences = useStore((state) => state.setSentences);
  const setCatThemes = useStore((state) => state.setCatThemes);
  const setCatComposer = useStore((state) => state.setCatComposer);

  // load data from the server
  const { data: lines, error: linesQueryError, loading: linesQueryLoading } = useQuery(GET_LINES);
  const { data: illustrations, error: illustrationsQueryError, loading: illustrationsQueryLoading } = useQuery(GET_ILLUSTRATIONS);
  const { data: tokens, error: tokensQueryError, loading: tokensQueryLoading } = useQuery(GET_TOKENS);
  const { data: themes, error: themesQueryError, loading: themesQueryLoading } = useQuery(GET_CAT_THEMES);
  const { data: composerCat, error: composerCatQueryError, loading: composerCatQueryLoading } = useQuery(GET_CAT_COMPOSER);

  // -- Helper functions -------------------------------------------------------- //
  const getRelatedThemes = (illustration, lines) => {
    var themes = [];
    // get all matching lines
    const matchingLines = lines.filter((line) => {
      return illustration.categories.some((item) => line.categories.includes(item));
    });
    const matchingThemesLines = matchingLines.map((line) => line.themes);
    const matchingUniqThemes = [...new Set(matchingThemesLines.flat())];

    themes = matchingUniqThemes;
    return themes;
  };

  // -- Process the loaded data -------------------------------------------------- //
  const processData = useCallback(
    (data) => {
      // -- Prepare lines data ------------------------------------------- //
      const preparedLines = data.lines.entries
        .map((lineData) => {
          var line = {
            id: parseInt(lineData.id),
            text: lineData.title,
            categories: lineData.categories.map((cat) => parseInt(cat.id)),
            themes: lineData.themes.map((cat) => parseInt(cat.id)),
            composeCategories: lineData.composeCategories.map((cat) => parseInt(cat.id))
          };
          return line;
        })
        .filter((item) => item);

      setSentences(preparedLines);

      // -- Prepare illustration data ------------------------------------- //
      const preparedIllustrations = data.illustrations.entries
        .map((illustrationData) => {
          if (!illustrationData.pngImage.length) return;
          if (!illustrationData.jpgimage.length) return;

          const imageDataPng = illustrationData.pngImage[0];
          const imageDataJpg = illustrationData.jpgimage[0];
          const layerTypes = {
            background: 0,
            middleground: 1,
            foreground: 2
          };

          // !! might be obsolete when everything is setup
          const minWidth = parseInt(illustrationData.minWidth) || 1;
          const maxWidth = parseInt(illustrationData.maxWidth) || 3;

          const preferredPos = illustrationData.preferredPosition === '' ? false : illustrationData.preferredPosition;

          var illustration = {
            id: parseInt(illustrationData.id),
            categories: illustrationData.categories.map((cat) => parseInt(cat.id)),
            themes: illustrationData.themes.map((cat) => parseInt(cat.id)),
            image: {
              minWidth: minWidth,
              maxWidth: maxWidth,
              preferredPosition: preferredPos,
              layer: illustrationData.imageLayer.map((layer) => layerTypes[layer]),
              url: process.env.REACT_APP_IMG_SOURCE + imageDataPng.url,
              aspect: imageDataPng.height / imageDataPng.width,
              imgWidth: imageDataPng.width,
              imgHeight: imageDataPng.height,
              previewUrl: process.env.REACT_APP_IMG_SOURCE + imageDataJpg.url,
              previewAspect: imageDataJpg.height / imageDataJpg.width,
              previewWidth: imageDataJpg.width,
              previewHeight: imageDataJpg.height
            }
          };

          // if no themes, get themes from related lines
          if (!illustration.themes.length) {
            illustration.themes = getRelatedThemes(illustration, preparedLines);
          }

          return illustration;
        })
        .filter((item) => item);

      setIllustrations(preparedIllustrations);

      // -- Prepare token data ------------------------------------------ //
      let tokens = data.tokens.entries.map((tokenData) => {
        var token = {
          id: parseInt(tokenData.id),
          matches: tokenData.matches.map((match) => match.match.toLowerCase().trim()).filter((filter) => filter),
          embed: tokenData.embed,
          title: tokenData.title,
          creator: tokenData.creator
        };

        return token;
      });
      setTokens(tokens);

      // -- Prepare themes cat data ------------------------------------------ //
      let themes = data.themes.categories.map((themeData) => {
        var theme = {
          id: parseInt(themeData.id),
          title: themeData.title
        };

        return theme;
      });
      setCatThemes(themes);

      // -- Prepare composer cat data ------------------------------------------ //
      let composerCats = data.composerCat.categories.map((composerCatData) => {
        var composerCat = {
          id: parseInt(composerCatData.id),
          title: composerCatData.title,
          slug: composerCatData.title.toLowerCase(),
          children: composerCatData.children.map((child) => {
            return {
              id: parseInt(child.id),
              title: child.title
            };
          })
        };

        return composerCat;
      });

      setCatComposer(composerCats);
    },
    [setIllustrations, setSentences, setTokens, setCatComposer, setCatThemes]
  );

  // load data
  useEffect(() => {
    if (lines && illustrations && tokens && themes && composerCat) {
      //
      processData({ lines, illustrations, tokens, themes, composerCat });

      setTimeout(() => {
        if (isNotFirstUse) {
          navigate('/dashboard');
        } else {
          navigate('/intro');
        }
      }, 2000);
    }
  }, [lines, illustrations, tokens, themes, composerCat, isNotFirstUse, navigate, processData]);

  return (
    <div className="loader">
      <div className="loader__container">
        <Lottie loop animationData={loaderAnimation} play style={{ width: '100%', height: '100%' }} />
      </div>
    </div>
  );
}

export default Loader;
