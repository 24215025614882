import { useEffect, useState, useRef } from 'react';

function UseContainerDrag(scrollerRef) {
  const startingPosition = { x: 0, y: 0 };
  const [dragInfo, setDragInfo] = useState({
    isDragging: false,
    isMoving: false,
    origin: { x: 0, y: 0 },
    translation: startingPosition,
    lastTranslation: startingPosition
  });
  const isMouseDevice = matchMedia('(hover:hover)').matches;
  const { isDragging, isMoving } = dragInfo;

  useEffect(() => {
    if (!scrollerRef.current) return;

    if (isMoving) {
      scrollerRef.current.classList.add('is-moving');
    } else {
      scrollerRef.current.classList.remove('is-moving');
    }
  }, [isMoving]);

  const handleMouseMove = ({ clientX, clientY }) => {
    if (!isMouseDevice || !isDragging) return;

    if (isDragging) {
      const bnd = scrollerRef.current.getBoundingClientRect();
      const { origin, lastTranslation } = dragInfo;

      let x = origin.x + lastTranslation.x - clientX;
      let y = 0;

      scrollerRef.current.scrollLeft = x;

      setDragInfo({
        ...dragInfo,
        isMoving: true,
        translation: { x: scrollerRef.current.scrollLeft, y: y }
      });
    }
  };

  const handleMouseDown = (ev) => {
    const { clientX, clientY } = ev;
    ev.preventDefault();
    ev.stopPropagation();

    if (!isMouseDevice) return;
    setDragInfo({ ...dragInfo, isMoving: false, isDragging: true, origin: { x: clientX, y: clientY } });
  };

  const handleMouseUp = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    if (!isMouseDevice) return;
    const { translation } = dragInfo;

    setDragInfo({ ...dragInfo, isMoving: false, isDragging: false, lastTranslation: { x: translation.x, y: translation.y } });
  };

  return {
    handleMouseDown,
    handleMouseMove,
    handleMouseUp
  };
}

export default UseContainerDrag;
