//https://blog.logrocket.com/build-modal-with-react-portals/
import { createPortal } from 'react-dom';
import OverlayPortal from './OverlayPortal';
import { Transition } from 'react-transition-group';

import { ReactComponent as IconClose } from '../assets/icons/icon-close.svg';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import IconButton from '../components/IconButton';

// -- Overlay Full -------------------------------------------------------------------------- //
function OverlayFull({ noAnimation, isVisible, handleClose, children, className }) {
  const nodeRef = useRef(null);
  const refPanel = useRef(null);

  let onEnter = () => {
    if (noAnimation) {
      gsap.set([refPanel.current], { opacity: 1 });
      return;
    }
    gsap.set([refPanel.current], { opacity: 0 });
    gsap.fromTo(refPanel.current, { opacity: 0 }, { delay: 0.2, duration: 0.2, opacity: 1 });
  };
  let onExit = () => {
    if (noAnimation) {
      gsap.set([refPanel.current], { opacity: 0 });
      return;
    }
    gsap.to(refPanel.current, { duration: 0.2, opacity: 0 });
  };

  return (
    <Transition in={isVisible} onEnter={onEnter} onExit={onExit} mountOnEnter unmountOnExit timeout={500} nodeRef={nodeRef}>
      <div className={`overlay overlay--full ${className}`} ref={nodeRef}>
        <div className="overlay__panel overlay-panel" ref={refPanel}>
          <div className="overlay-panel__body">{children}</div>
        </div>
      </div>
    </Transition>
  );
}

// -- Overlay Panel -------------------------------------------------------------------------- //
function OverlayPanel({ noAnimation, isVisible, overlayType, title, children, handleClose, className }) {
  const nodeRef = useRef(null);

  const refPanel = useRef(null);
  const refBackdrop = useRef(null);

  let onEnter = () => {
    gsap.set([refPanel.current, refBackdrop.current], { opacity: 0 });
    gsap.to(refBackdrop.current, { duration: 0.2, opacity: 1 });
    gsap.fromTo(refPanel.current, { opacity: 1, y: '100%' }, { delay: 0.2, duration: 0.2, opacity: 1, y: '0%' });
  };
  let onExit = () => {
    gsap.to(refPanel.current, { duration: 0.2, opacity: 0 });
    gsap.to(refBackdrop.current, { duration: 0.2, opacity: 0 });
  };

  return (
    <Transition in={isVisible} onEnter={onEnter} onExit={onExit} mountOnEnter unmountOnExit timeout={500} nodeRef={nodeRef}>
      <div className={`overlay overlay--panel ${className ? className : ''}`} ref={nodeRef}>
        <div className="overlay__backdrop" ref={refBackdrop}></div>
        <div className="overlay__panel overlay-panel" ref={refPanel}>
          <div className="overlay-panel__header">
            <h3>{title}</h3>

            <IconButton Icon={IconClose} onClick={handleClose} />
          </div>
          <div className="overlay-panel__body">{children}</div>
        </div>
      </div>
    </Transition>
  );
}

// -- Overlay Token -------------------------------------------------------------------------- //
function OverlayPanelToken({
  noAnimation,
  isVisible,
  overlayType,
  title,
  children,
  handleClose,
  className,
  token,
  tokenCollect
}) {
  const nodeRef = useRef(null);

  const refAwardSplash = useRef(null);
  const refAwardBadge = useRef(null);
  const refPanel = useRef(null);
  const refBackdrop = useRef(null);

  let onEnter = () => {
    gsap.set([refPanel.current, refBackdrop.current, refAwardBadge.current], { opacity: 0 });
    gsap.to(refBackdrop.current, { duration: 0.2, opacity: 1 });

    if (tokenCollect) {
      // gsap.fromTo(refPanel.current, { opacity: 1, y: '100%' }, { delay: 0.2, duration: 0.2, opacity: 1, y: '0%' });
      animateTokenCollect();
    } else {
      gsap.fromTo(refPanel.current, { opacity: 1, y: '100%' }, { delay: 0.2, duration: 0.2, opacity: 1, y: '0%' });
    }
    // if (tokenCollect) {
    //}
  };

  let animateTokenCollect = () => {
    refAwardSplash.current.innerHTML = '';

    const bnd = refAwardSplash.current.getBoundingClientRect();

    // awards splash
    let nrOfSplashes = 6;
    let splashers = [];
    for (let i = 0; i < nrOfSplashes; i++) {
      let splash = document.createElement('div');
      splash.innerHTML = '+1';
      splash.classList.add('splash-node');
      refAwardSplash.current.appendChild(splash);
      splashers.push(splash);
    }

    // position splashes random
    gsap.set(splashers, { opacity: 1, x: () => Math.random() * bnd.width - 50 + 25, y: () => bnd.height + Math.random() * 200 });

    // base timeline
    let timeline = gsap.timeline({ paused: true });
    splashers.forEach((splasher) => {
      timeline.to(
        splasher,
        {
          y: -100,
          delay: Math.random() * 0.7,
          rotateY: 360 * 2,
          ease: 'Power.easeOut',
          duration: 1.2,
          force3D: true
        },
        0
      );
    });

    // animate panel
    timeline.fromTo(
      refPanel.current,
      { opacity: 1, y: '100%' },
      { delay: 0.2, duration: 0.8, opacity: 1, y: '0%', ease: 'Power2.easeOut' },
      0.9
    );

    // animate award badge
    timeline.fromTo(refAwardBadge.current, { opacity: 0, y: 100 }, { opacity: 1, duration: 0.5, y: 0 }, 1);
    timeline.fromTo(
      refAwardBadge.current,
      { rotateY: 0, perspective: '200px' },
      { duration: 3, perspective: '200px', opacity: 1, rotateY: 360 * 2, ease: 'Power4.easeOut' },
      1
    );

    // increase performance
    timeline.progress(1).progress(0);
    timeline.play();
  };

  let onExit = () => {
    gsap.to(refPanel.current, { duration: 0.2, opacity: 0 });
    gsap.to(refBackdrop.current, { duration: 0.2, opacity: 0 });
    if (refAwardBadge.current) {
      gsap.to(refAwardBadge.current, { duration: 0.2, opacity: 0 });
    }
  };

  return (
    <Transition in={isVisible} onEnter={onEnter} onExit={onExit} mountOnEnter unmountOnExit timeout={500} nodeRef={nodeRef}>
      <div
        className={`overlay overlay--panel overlay--token-collect ${tokenCollect ? 'is-collecting' : ''} ${
          className ? className : ''
        }`}
        ref={nodeRef}>
        <div className="overlay__backdrop" ref={refBackdrop}></div>
        <div className="awards-splash" ref={refAwardSplash}></div>
        <div className="awards-header">
          <div className="award-badge" ref={refAwardBadge}>
            +1
          </div>
        </div>

        <div className="overlay__panel overlay-panel" ref={refPanel}>
          <div className="overlay-panel__header">
            <h3>{title}</h3>

            <IconButton Icon={IconClose} onClick={handleClose} />
          </div>
          <div className="overlay-panel__body">{children}</div>
        </div>
      </div>
    </Transition>
  );
}

// -- Overlay -------------------------------------------------------------------------------- //
function Overlay(props) {
  return (
    <OverlayPortal>
      {props.overlayType === 'full' && <OverlayFull {...props} />}
      {props.overlayType === 'panel' && <OverlayPanel {...props} />}
      {props.overlayType === 'token' && <OverlayPanelToken {...props} />}
    </OverlayPortal>
  );
}

export default Overlay;
