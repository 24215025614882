//https://blog.logrocket.com/build-modal-with-react-portals/
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import Overlay from '../Overlay';
import { Transition } from 'react-transition-group';
import { useRef } from 'react';
import gsap from 'gsap';
import useWorkStore from '../../store/workStore';
import useStore from '../../store/store';
import { ReactComponent as VerversLogo } from '../../assets/images/ververs-logo.svg';

import ColorList from '../ColorList';

function PageColofon({ onContentSelect, visible, onClose, tokenId }) {
  const nodeRef = useRef(null);
  const tokens = useStore((state) => state.tokens);
  const [selectedColor, setSelectedColor] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState(false);
  const [noAnimation, setNoAnimation] = useState(true);

  const colors = ['brown', 'purple', 'blue', 'orange', 'red'];

  // find one token by id
  const token = tokens.find((token) => token.id === tokenId);
  // console.log(token);
  // reset
  useEffect(() => {}, [setSelectedMethod]);

  // useLayoutEffect(() => {
  //   setWorkColor(selectedColor);
  // }, [selectedColor, setWorkColor]);

  // const onCloseOverlay = () => {
  //   setNoAnimation(false);
  //   setVisible(false);
  // };

  //   const onEnter = () => {
  //     // console.log('aaa');
  //     gsap.fromTo(nodeRef.current, { x: '100%' }, { duration: 0.2, x: '0%' });
  //   };
  //   const onExit = () => {};

  // console.log('visible');
  // console.log(visible);
  return (
    <Overlay className="overlay--default-text" title="Colofon" isVisible={visible} overlayType={'panel'} handleClose={onClose}>
      <div className="page-colofon container richtext">
        {/* <p className="intro">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec id venenatis nisi. Aenean tincidunt at ex non vestibulum.
        </p> */}

        <p>Deze productie is mogelijk gemaakt door:</p>
        <ul>
          <li>
            <a href="https://www.letterenfonds.nl/" target="_BLANK" rel="noreferrer">
              Nederlands Letterenfonds
            </a>
          </li>
          <li>
            <a href="https://www.fonds21.nl/" target="_BLANK" rel="noreferrer">
              Fonds 21
            </a>
          </li>
          <li>
            <a href="https://www.lirafonds.nl/" target="_BLANK" rel="noreferrer">
              Stichting Lira
            </a>
          </li>
          <li>
            <a href="https://www.zabawas.nl/" target="_BLANK" rel="noreferrer">
              Stichting Zabawas
            </a>
          </li>
        </ul>

        <h4>Illustraties</h4>
        <ul>
          <li>
            <a href="https://www.liesvangasse.be" target="_BLANK" rel="noreferrer">
              Lies van Gasse
            </a>
          </li>
        </ul>
        <h4>Dichtregels</h4>
        <ul>
          <li>
            <a href="https://www.debezigebij.nl/auteur/vicky-francken/" target="_BLANK" rel="noreferrer">
              Vicky Francken
            </a>
          </li>
        </ul>

        <h4>Ontwerp &amp; realisatie</h4>
        <ul>
          <li>
            <a href="https://www.jeroenbraspenning.nl" target="_BLANK" rel="noreferrer">
              Jeroen Braspenning
            </a>
          </li>
        </ul>

        <h4>Credits</h4>
        <ul>
          <li>
            <a href="https://www.bibliotheekmb.nl/" target="_BLANK" rel="noreferrer">
              Bibliotheek Midden Brabant
            </a>
          </li>
          <li>
            <a href="https://www.tilt.nu" target="_BLANK" rel="noreferrer">
              Tilt
            </a>
          </li>
          <li>
            <a href="https://www.tilburguniversity.edu/" target="_BLANK" rel="noreferrer">
              Tilburg University
            </a>
          </li>
        </ul>

        <div className="logo-footer">
          <VerversLogo />
        </div>
        <p>
          © {new Date().getFullYear()}
          <br />
          <br />
        </p>
      </div>
    </Overlay>
  );
}

export default PageColofon;
