//https://blog.logrocket.com/build-modal-with-react-portals/
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import Overlay from '../Overlay';
import { Transition } from 'react-transition-group';
import { useRef } from 'react';
import gsap from 'gsap';
import SelectIllustration from './SelectIllustration';
import SelectSentence from './SelectSentence';

function AddContentOverlay({ visible, allowedLayers = [], type, onClose, onSelect }) {
  const onContentSelect = (data) => {
    onSelect({ type, ...data });
  };

  return (
    <Overlay className="overlay--add-content" isVisible={visible} overlayType={'panel'} handleClose={onClose}>
      {type === 'illustration' && (
        <SelectIllustration allowedLayers={allowedLayers} onSelect={onContentSelect}></SelectIllustration>
      )}
      {type === 'sentence' && <SelectSentence onSelect={onContentSelect}></SelectSentence>}
    </Overlay>
  );
}

export default AddContentOverlay;
