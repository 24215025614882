import React, { useContext, useEffect, useRef, useState } from 'react';
import DialogProvider from '../components/Dialog/DialogProvider';
import { useDialog } from '../components/Dialog/DialogProvider';
// -- Dialog container ----------------------------------------------------------------------------------------------

// -- Dialog provider ----------------------------------------------------------------------------------------------

// -- Page Dialog Content
function PageDialogContent() {
  const [openDialog, closeDialog] = useDialog();
  const [test, setTestState] = useState(0);
  const interval = useRef(null);
  // get current date and time
  const date = new Date();

  useEffect(() => {
    interval.current = setInterval(() => {
      setTestState(test + 1);
    }, 5000);
    return () => {
      clearInterval(interval.current);
    };
  }, [interval, test]);

  const otherFunction = () => {};
  const doOpenDialog = () => {
    openDialog({
      callbackFn: otherFunction
    });
  };
  return (
    <>
      <div>dialog testing</div>
      <button onClick={doOpenDialog}> Show dialog</button>
      {/* <DialogOverlay>
        <div className="dialog-test">aaa</div>
      </DialogOverlay> */}
    </>
  );
}
// -- Page dialog provider ----------------------------------------------------------------------------------------------
function PageDialog() {
  return (
    <>
      <PageDialogContent />
    </>
  );
}

export default PageDialog;

//
// const addToEnd = useCallback(() => {
//   setNextLinePosition(workLines.length + 1);
//   showModal("text");
// }, [workLines]);
