// The illustration composer is responsible for the following:
// -
// -
// -
// -

export default class IllustrationComposer {
  constructor(gridSize) {
    this.defaultParams = {};
    this.illustrationSet = [];
    this.gridSize = gridSize;
    this.gridMatrix = this.gridMatrix = this.createEmptyMatrix();

    this.nrCellRows = this.gridSize;
    this.nrCellCols = this.gridSize;

    const BACKGROUND = 0;
    const MIDDLEGROUND = 1;
    const FOREGROUND = 2;

    const BOTTOM = 0;
    const TOP = 2;

    this.nextLayerType = [BACKGROUND];

    this.nrOfBackgrounds = Math.floor(Math.random() * 1) + 2;
  }

  getIllustrationIds() {
    return this.illustrationSet.map((item) => item.illustration.id);
  }
  addIllustration(illustration) {
    let maxWidth = illustration.maxWidth;
    let minWidth = illustration.minWidth;

    let xSize = Math.round(minWidth + Math.random() * (maxWidth - minWidth));
    let ySize = xSize * illustration.aspect;
    if (ySize < 1) {
      ySize = 1;
    }

    // if the image is larger than the grid, we need to scale it down
    if (ySize > this.gridSize) {
      ySize = this.gridSize;
      xSize = ySize / illustration.aspect;
    }

    // find the optimal position for the image
    let availablePositions = this.getOptimalPositions(xSize, ySize, this.gridMatrix, this.gridSize);

    if (!availablePositions.length) {
      window.alert('hmm.. no available positions');
      return;
    }

    // if there is a preferred position, try to find these first
    let preferredPosMatches = [];
    let hasPreferredPos = illustration.preferredPosition;
    if (hasPreferredPos && hasPreferredPos !== 'anywhere') {
      preferredPosMatches = availablePositions.filter((item) => {
        if (hasPreferredPos === 'top') {
          return item.y === 0;
        }
        if (hasPreferredPos === 'bottom') {
          return item.y === 2;
        }
        return false;
      });
    }

    let selectedPos = {};
    let preFilteredMatches = availablePositions;

    // if there are preferredpos matches use these
    if (preferredPosMatches.length > 0) {
      preFilteredMatches = preferredPosMatches;
    }

    // sort the matches based ont the score
    let scoreSortedMatches = preFilteredMatches.sort((a, b) => (a.score > b.score ? 1 : -1));

    // if there are multiple positions with the same score, ignore the lower score ones
    if (scoreSortedMatches.length > 1) {
      let similarScoresList = scoreSortedMatches.filter((pos) => pos.score === scoreSortedMatches[0].score);
      // if (similarScoresList.length > 1) {
      preFilteredMatches = similarScoresList;
      // }
      // similarScorePos = scoreSortedMatches.filter((pos) => pos.score === scoreSortedMatches[0].score);
    }

    // select one
    selectedPos = preFilteredMatches[0];
    if (preFilteredMatches.length > 1) {
      selectedPos = preFilteredMatches[Math.floor(Math.random() * preFilteredMatches.length)];
    }
    // if there is a preferred pos, make sure it is used, this can cause overlapping because
    // we don't check for collisions
    // if (illustration.preferredPos) {
    //   if (illustration.preferredPos === 'top') {
    //     randomPos.y = 0;
    //   }
    //   if (illustration.preferredPos === 'bottom') {
    //     randomPos.y = this.gridSize - 1;
    //   }
    // }

    // if the pos in combination with the width is too large, reposition

    let xPos = selectedPos.x;
    let yPos = selectedPos.y;

    if (xPos + xSize > this.gridSize) {
      xPos = this.gridSize - xSize;
    }

    if (yPos + ySize > this.gridSize) {
      yPos = this.gridSize - ySize;
    }

    // create a matrix for the given position
    let illustrationMatrix = this.createMatrix({
      x: selectedPos.x,
      y: selectedPos.y,
      width: selectedPos.width,
      height: selectedPos.height
    });
    // this.logMatrix(illustrationMatrix);

    let positioning = {
      xSize: xSize,
      ySize: ySize,
      xPos: xPos,
      yPos: yPos
    };

    let struct = {
      id: illustration.id,
      illustration: illustration,
      positioning: positioning,
      matrix: illustrationMatrix
    };

    this.illustrationSet.push(struct);

    this.sumMatrices();

    // this.logMatrix(this.gridMatrix);

    this.calculateNextLayerRecipe();

    return struct;
  }

  // the next layer recipe is used to determine the next layer type
  // we want a certain ritme in the layer types so the image becomes as
  // visually appealing as possible
  calculateNextLayerRecipe() {
    if (this.illustrationSet.length >= this.nrOfBackgrounds) {
      this.nextLayerType = [2];

      // randomly allow a middleground, don't know if this is how we like it
      if (Math.random() > 0.7) {
        console.warn('middleground!');
        this.nextLayerType = [1, 2];
      }
    }
  }

  //   !! finish this
  changePosition(id, axis, value) {
    let layerIndex = this.illustrationSet.findIndex((item) => item.id === id);
    let layer = { ...this.illustrationSet[layerIndex] };

    let posAttribute = axis === 'x' ? 'xPos' : 'yPos';
    let newPos = layer.positioning[posAttribute] + value;
    if (newPos > this.gridSize - 1) {
      newPos = this.gridSize - 1;
    } else if (newPos < 0) {
      newPos = 0;
    }

    layer.positioning[posAttribute] = newPos;
    layer.matrix = this.createMatrix({
      x: layer.positioning.xPos,
      y: layer.positioning.yPos,
      width: layer.positioning.xSize,
      height: layer.positioning.ySize
    });

    this.illustrationSet[layerIndex] = layer;
    return layer;

    // console.log(id);
    // console.log(axis);
    // console.log(value);
    // console.log(this.illustrationSet);
  }

  changeScale(id, value) {
    let layerIndex = this.illustrationSet.findIndex((item) => item.id === id);
    let layer = { ...this.illustrationSet[layerIndex] };

    let currentXSize = layer.positioning.xSize;
    let currentYSize = layer.positioning.ySize;
    let newXSize = currentXSize + value;
    // console.log(layer);
    let newYSize = newXSize * layer.illustration.aspect;
    if (newYSize < 1) {
      newYSize = 1;
    }

    // layer.positioning.xSize = 0;
    // layer.positioning.ySize = 0;
    if (newXSize <= this.gridSize && newYSize <= this.gridSize && newXSize >= 0 && newYSize >= 0) {
      layer.positioning.xSize = newXSize;
      layer.positioning.ySize = newYSize;

      let xPos = layer.positioning.xPos;
      let yPos = layer.positioning.yPos;

      if (xPos + newXSize > this.gridSize - 1) {
        xPos = this.gridSize - newXSize;
      }

      if (yPos + newYSize > this.gridSize - 1) {
        yPos = this.gridSize - newYSize;
      }

      layer.positioning.xPos = xPos;
      layer.positioning.yPos = yPos;

      layer.matrix = this.createMatrix({
        x: xPos,
        y: yPos,
        width: layer.positioning.xSize,
        height: layer.positioning.ySize
      });
    }

    this.illustrationSet[layerIndex] = layer;

    return layer;
  }

  // -- Positioning helpers ----------------------------------------- //
  logMatrix(matrix) {
    console.log('-----');
    let rows = [];
    for (let a = 0; a < this.gridSize; a++) {
      let start = a * this.gridSize;
      rows.push(matrix.slice(start, start + this.gridSize).join(','));
    }
    rows.forEach((row) => {
      console.log(row);
    });
    console.log('-----');
  }

  createEmptyMatrix() {
    return new Array(this.gridSize * this.gridSize).fill(0);
  }
  createMatrix({ x, y, width, height }) {
    let emptyMatrix = this.createEmptyMatrix();

    for (let a = x; a < x + width; a++) {
      for (let b = y; b < y + height; b++) {
        let cell = [b * this.gridSize + a];

        emptyMatrix[cell] = 1;
      }
    }

    return emptyMatrix;
  }

  sumMatrices() {
    this.gridMatrix = this.createEmptyMatrix();
    this.illustrationSet.forEach((item) => {
      this.addMatrixValue(item.matrix);
    });
    // this.logMatrix(this.gridMatrix);
  }

  addMatrixValue(matrix) {
    this.gridMatrix.forEach((value, index) => {
      this.gridMatrix[index] = value + matrix[index];
    });

    // this.logMatrix(this.gridMatrix);
  }

  // calculate the score of a proposed position by getting the average or sum
  // of the space the image would occupy
  calculateMatrixValue(x, y, width, height, matrix) {
    // console.log(`calculateMatrixValue x:${x} y:${y} w:${width} h:${height}`);
    let positions = width * height;
    let values = [];
    for (let b = y; b < y + height; b++) {
      for (let a = x; a < x + width; a++) {
        values.push(matrix[b * this.gridSize + a]);
      }
    }

    const sum = values.reduce((a, b) => a + b, 0);
    const average = values.reduce((a, b) => a + b, 0) / values.length;
    return average;
  }

  getById(id) {
    // var result = this.illustrationSet.find((item) => item.illustration.id === id);
    // return this.illustrationSet.find((item) => item.illustration.id === id);
  }
  removeById(id) {
    let index = this.illustrationSet.findIndex((item) => item.illustration.id === id);

    if (index > -1) {
      this.illustrationSet.splice(index, 1);
    }

    this.sumMatrices();
  }
  getOptimalPositions(width, height, gridMatrix, gridSize) {
    // console.log(`get optimal position w:${width} h:${height} gSize:${gridSize} `);

    width = Math.floor(width);
    height = Math.floor(height);

    if (height > gridSize) {
      // image was bigger than grid
      height = gridSize;
    }

    let availableCoordinates = [];

    // not smaller than 0
    let nXOptions = Math.max(0, gridSize - width);
    let nYOptions = Math.max(0, gridSize - height);

    // console.log(`nXOptions: ${nXOptions} nYOptions: ${nYOptions}`);

    // walk through the available positions
    for (let x = 0; x <= nXOptions; x++) {
      for (let y = 0; y <= nYOptions; y++) {
        let matrixValue = this.calculateMatrixValue(x, y, width, height, gridMatrix);
        availableCoordinates.push({
          x: x,
          y: y,
          width: width,
          height: height,
          score: matrixValue
        });
      }
    }
    return availableCoordinates;
  }

  import(illustrations) {
    this.reset();
    illustrations.forEach((illustration) => {
      this.illustrationSet.push(illustration);
    });

    this.sumMatrices();
  }

  // -- Composition ----------------------------------------- //
  // returns the ideal next layer based on the composition recipe
  nextLayer() {
    // return [];
    return this.nextLayerType;
  }

  // --------------------------------------------------------- //
  reset() {
    this.gridMatrix = this.createEmptyMatrix();
    this.illustrationSet = [];
    // console.log('reset');
  }
}
