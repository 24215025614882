//https://blog.logrocket.com/build-modal-with-react-portals/
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { createPortal } from 'react-dom';
import Overlay from '../Overlay';
import { Transition } from 'react-transition-group';
import { useRef } from 'react';
import gsap from 'gsap';
import useWorkStore from '../../store/workStore';
import useStore from '../../store/store';
import loaderAnimation from '../../assets/animations/loader-lottie.json';
import Lottie from 'react-lottie-player';

import ColorList from '../ColorList';

function TokenOverlayContent({ token, isCollecting }) {
  const iframeRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [renderIframe, setRenderIframe] = useState(false);

  useEffectOnce(() => {
    var additionalTimeout = 0;
    if (isCollecting) {
      additionalTimeout = 2000;
    }

    setTimeout(() => {
      setRenderIframe(true);
    }, 300 + additionalTimeout);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000 + additionalTimeout);
  }, []);

  return (
    token && (
      <div className={`token-overlay ${isLoading ? 'is-loading' : ''}`}>
        <div
          ref={iframeRef}
          className="token-overlay__embed"
          style={{
            aspectRatio: token.embed.imageWidth / token.embed.imageHeight
          }}>
          {renderIframe && <div dangerouslySetInnerHTML={{ __html: token.embed.code }}></div>}
          <div className="token-overlay__loader">
            <div>
              <Lottie loop animationData={loaderAnimation} play style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
        </div>
        {token.creator && <span className="label">{token.creator}</span>}
        <h2>{token.title}</h2>
        {/* <h1>token overlay</h1>
        {token && (
          <>
            <h3>{token.title}</h3>
            <div className="content" dangerouslySetInnerHTML={{ __html: token.embed.code }}></div>
          </>
        )} */}
      </div>
    )
  );
  // console.log('visible');
  // console.log(visible);
}

function TokenOverlay({ onContentSelect, visible, onClose, tokenId, tokenIsCollecting }) {
  // const [showOverlay, setShowOverlay] = useState(false);
  const tokens = useStore((state) => state.tokens);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setShowOverlay(visible);
  //   }, 1000);
  // }, [visible]);

  const token = tokens.find((token) => token.id === tokenId);

  return (
    <Overlay
      isVisible={visible}
      title={tokenIsCollecting ? 'Token gevonden!' : ''}
      overlayType={'token'}
      handleClose={onClose}
      tokenCollect={tokenIsCollecting}>
      <TokenOverlayContent token={token} isCollecting={tokenIsCollecting}></TokenOverlayContent>
    </Overlay>
  );
}

export default TokenOverlay;
