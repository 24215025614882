import { gql } from '@apollo/client';

// -- FINAL QUERIES ------------------------------------------------------------------------ //
export const GET_LINES = gql`
  query GetLines {
    entries(section: "lines") {
      title
      id
      ... on lines_default_Entry {
        id
        categories(title: "") {
          id
        }
        composeCategories {
          id
        }
        themes {
          id
        }
      }
    }
  }
`;

export const GET_ILLUSTRATIONS = gql`
  query GetIllustrations {
    entries(section: "illustrations") {
      id
      title
      ... on illustrations_default_Entry {
        id
        imageLayer
        maxWidth
        minWidth
        preferredPosition
        categories {
          id
          title
        }
        composeCategories {
          id
        }
        themes {
          id
        }
        pngImage(id: "", uri: "") {
          id
          width
          height
          url @imagerTransform(width: 1000, format: "png")
        }
        jpgimage {
          id
          width
          height
          url @imagerTransform(width: 500, format: "jpg")
          ... on illustrations_Asset {
            categories(title: "") {
              id
              title
            }
          }
        }
      }
    }
  }
`;

export const GET_CAT_THEMES = gql`
  query GetCatThemes {
    categories(group: "themeCategories", relatedToEntries: {}) {
      id
      title
    }
  }
`;

export const GET_CAT_COMPOSER = gql`
  query GetCatComposer {
    categories(group: "composeCategories", hasDescendants: true, relatedToEntries: {}) {
      id
      title
      children {
        id
        title
      }
    }
  }
`;

export const GET_TOKENS = gql`
  query GetEasterEggs {
    entries(section: "easterEggs") {
      ... on easterEggs_default_Entry {
        id
        creator
        title
        embed {
          title
          url
          type
          image
          imageWidth
          imageHeight
          code
        }
        matches {
          match
        }
      }
    }
  }
`;

// -- PREVIOUS QUERIES --------------------------------------------------------------------- //

export const GET_CAT_GENERAL = gql`
  query GetCatGeneral {
    categories(group: "themeCategories") {
      id
      title
    }
  }
`;

export const GET_SENTENCES = gql`
  {
    entries(section: "sentence") {
      title
    }
  }
`;

export const GET_CATEGORIES = gql`
  query GetCategories {
    categories {
      title
      id
    }
  }
`;

// export const GET_EASTER_EGGS = gql`
//   query GetEasterEggs {
//     entries(section: "easterEggs") {
//       ... on easterEggs_default_Entry {
//         id
//         title
//         creator
//         embed {
//           title
//           url
//           type
//           image
//           imageWidth
//           imageHeight
//           code
//         }
//         matches {
//           match
//         }
//       }
//     }
//   }
// `;

// export const VIEW_USERS = gql`
//   query ($id: Int){
//     getUserInfo(id: $id) {
//       id,
//       name,
//       job_title,
//       email
//     }
//   }
// `;

// export const ADD_USER = gql`
//   mutation($name: String, $email: String, $job_title: String) {
//     createUser (name: $name, email: $email, job_title: $job_title)
//   }
// `;

// export const EDIT_USER = gql`
//   mutation($id: Int, $name: String, $email: String, $job_title: String) {
//     updateUserInfo (id: $id, name: $name, email: $email, job_title: $job_title)
//   }
// `;

// export const DELETE_USER = gql`
//   mutation($id: Int) {
//     deleteUser(id: $id)
//   }
// `
