import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Provider as BusProvider, useBus, useListener } from 'react-bus';
import { useUpdateEffect } from 'react-use';

import GeneratorCanvas from '../components/Generator/GeneratorCanvas';
import GeneratorToolbar from '../components/Generator/GeneratorToolbar';
import GeneratorWorkSentences from '../components/Generator/GeneratorWorkSentences';
import { useDialog } from '../components/Dialog/DialogProvider';
import { useRouteChangeDialog } from '../hooks/useRouteBlocker';
import useStore from '../store/store';
import useWorkStore from '../store/workStore';
import { useNavigate, useLocation } from 'react-router-dom';

import StartNew from '../components/Overlays/StartNew';
import AddContentOverlay from '../components/Overlays/AddContentOverlay';
import { useDialogConfirm } from '../hooks/useDialogConfirm';
import GeneratorOverlay from '../components/Overlays/GeneratorOverlay';
import { prepareIllustrationObj, getMatchingIllustration, getMatchingSentence } from '../common/GeneratorHelpers';
import SentencesRenderer from '../components/SentencesRenderer';
import IllustrationComposer from '../common/IllustrationComposer';
import gsap from 'gsap';
import { colors } from '../common/Color';

function useIllustrationComposer(gridSize) {
  const isInitialized = useRef(false);
  const illustrationComposer = useRef();
  useEffect(() => {
    illustrationComposer.current = new IllustrationComposer(gridSize);
    isInitialized.current = true;
  }, []);

  return { illustrationComposer: illustrationComposer.current };
}

// -- Generator --------------------------------------------------------
function Generator() {
  const navigate = useNavigate();

  const [openDialog, closeDialog] = useDialog();

  const gridSize = 3;
  const [showContentOverlay, setShowContentOverlay] = useState(false);
  const [showGeneratorOverlay, setShowGeneratorOverlay] = useState(false);
  const setWorkColor = useWorkStore((state) => state.setWorkColor);

  const [currentContentType, setCurrentContentType] = useState(false);

  const illustrations = useStore((state) => state.illustrations);
  const sentences = useStore((state) => state.sentences);
  const workRemove = useStore((state) => state.removeWork);
  const workImport = useWorkStore((state) => state.import);
  const [autoScroll, setAutoScroll] = useState(false);

  const setAppLoading = useStore((state) => state.setAppLoading);

  const workState = useWorkStore((state) => state);
  const workColor = useWorkStore((state) => state.workColor);
  const stateResetWork = useWorkStore((state) => state.resetWork);
  const addWorkSentence = useWorkStore((state) => state.addSentence);
  const addWorkIllustration = useWorkStore((state) => state.addIllustration);
  const replaceWorkIllustration = useWorkStore((state) => state.replaceIllustration);

  const removeSentence = useWorkStore((state) => state.removeSentence);
  const removeIllustration = useWorkStore((state) => state.removeIllustration);

  const workSentences = useWorkStore((state) => state.sentences);
  const workIllustrations = useWorkStore((state) => state.illustrations);
  const location = useLocation();

  const [nextSentencePos, setNextSentencePos] = useState(0);
  //
  const saveWorkToStore = useStore((state) => state.saveWork);
  const saveWorkReplaceToStore = useStore((state) => state.replaceWork);
  const [curSentenceId, setCurSentenceId] = useState(null);
  const [curIllustrationId, setCurIllustrationId] = useState(null);
  const [allowedLayers, setAllowedLayers] = useState([0]);
  const setHasChanged = useWorkStore((state) => state.setHasChanged);

  const [isPlaying, setIsPlaying] = useState(false);
  const [timelineProgress, setTimelineProgress] = useState(null);
  const [playheadIllustrationId, setPlayheadIllustrationId] = useState(null);
  const [playheadSentenceId, setPlayheadSentenceId] = useState(null);
  // this ref has access to the generator class for instance to generate images on demand.
  const generatorCanvasClassRef = useRef();

  const [nrProgressSections, setNrProgressSections] = useState(0);

  const progressRef = useRef(null);
  // illustration generator
  // const currentWorkMatrix = useStore((state) => state.currentWork.layers);

  var usedIllustrationsIds = useMemo(() => {
    // console.log(workIllustrations);
    return workIllustrations.map((illustration) => illustration.id);
  }, [workIllustrations]);

  var usedSentencesIds = useMemo(() => {
    return workSentences.map((item) => item.id);
  }, [workSentences]);
  // useEffect(() => {
  //   console.log('usefffect');
  // });
  const { illustrationComposer } = useIllustrationComposer(gridSize);
  const date = new Date();

  const [showWizard, setShowWizard] = useState(false);

  useEffect(() => {
    return () => {
      stateResetWork();
      setWorkColor(null);
    };
  }, []);
  const resetWork = useCallback(() => {
    stateResetWork();
    if (illustrationComposer) {
      illustrationComposer.reset();
    }
  }, [stateResetWork, illustrationComposer]);

  useEffect(() => {
    if (location.state && location.state.showWizard) {
      resetWork();
      setShowWizard(true);
    }
    if (location.state && location.state.loadWork) {
      loadWork(location.state.loadWork);
    }

    if (location.state && location.state.showGenerator) {
      setShowGeneratorOverlay(true);
    }
  }, [location, resetWork]);

  useEffect(() => {
    // console.log('work sentence changed');
  }, [workSentences]);

  // -- Generator Helpers ------------------------------------------------
  const getSentenceById = (id) => {
    return workSentences.find((item) => item.id === id);
  };
  const getIllustrationById = (id) => {
    return workIllustrations.find((item) => item.id === id);
  };
  // -- Generator Functions ---------------------------------------------------
  // Generator --------------------------------------------------------

  const saveWork = async () => {
    setAppLoading(true);
    setCurSentenceId(null);
    setCurIllustrationId(null);
    console.warn('set loading');
    // console.log('do the save 2');
    // console.log(workColor);
    // console.log(workSentences);

    var savePromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        const imageDataUrl = generatorCanvasClassRef.current.generateThumb();
        resolve(imageDataUrl);
      }, 2000);
    }).then((thumb) => {
      const work = {
        thumb: thumb,
        workColor: workColor,
        sentences: [...workSentences],
        illustrations: [...workIllustrations]
      };

      setTimeout(() => {
        if (workState.type === 'loaded') {
          saveWorkReplaceToStore({ ...work, id: workState.workId });
        } else {
          saveWorkToStore(work);
        }
        setAppLoading(false);

        setHasChanged(false);
      }, 250);

      // setTimeout(() => {
      //   setAppLoading(false);
      // }, 1000);
    });

    return savePromise;
    // return new Promise((resolve, reject) => {

    //   resolve(true);
    // }).then()

    // saveWorkToStore()
  };

  // gets a matching illustration by

  const generateIllustrationLayer = (illustration) => {
    let illustrationObj = illustrationComposer.addIllustration(illustration);
    return { ...illustrationObj };
  };

  const illustrationChangePos = (illustrationId, axis, value) => {
    var updatedLayer = illustrationComposer.changePosition(illustrationId, axis, value);
    replaceWorkIllustration(updatedLayer);
    setHasChanged(true);
  };

  const illustrationChangeScale = (illustrationId, value) => {
    // console.log('aaa');
    var updatedLayer = illustrationComposer.changeScale(illustrationId, value);

    replaceWorkIllustration(updatedLayer);
    setHasChanged(true);
  };
  // let illustrationPos =
  //     compositionGenerator.current.addIllustration(illustrationPart)

  const loadWork = (work) => {
    workImport(work);

    if (illustrationComposer) {
      illustrationComposer.import(work.illustration);
    }

    setAutoScroll(true);
  };

  const addSentence = (sentence, overridePos = null) => {
    // console.log('-add sentence');
    // console.log(sentence.text);
    // set the illustration part
    let matchingIllustration = getMatchingIllustration(
      illustrations,
      sentence,
      usedIllustrationsIds,
      illustrationComposer.nextLayer()
    );
    let illustrationLayer = generateIllustrationLayer(matchingIllustration);

    // make a connection between illustration and sentence
    sentence.relId = illustrationLayer.illustration.id;
    illustrationLayer.relId = sentence.id;

    // add the usedId's manually, because the generator calls this function repeatedly and the state is not yet updated at that time
    usedIllustrationsIds.push(illustrationLayer.illustration.id);
    usedSentencesIds.push(sentence.id);

    addWorkSentence(sentence, overridePos ?? nextSentencePos);
    addWorkIllustration(illustrationLayer);
  };

  const addIllustration = (illustration) => {
    let sentence = getMatchingSentence(sentences, illustration, usedSentencesIds);

    let illustrationLayer = generateIllustrationLayer(prepareIllustrationObj(illustration));

    sentence.relId = illustrationLayer.id;
    illustrationLayer.reldId = sentence.id;

    addWorkSentence(sentence, nextSentencePos);
    addWorkIllustration(illustrationLayer);
  };

  const onSelect = (id) => {
    // console.log(id);

    setIsPlaying(false);

    if (!id) {
      setCurSentenceId(null);
      return;
    }
    if (curSentenceId === id) {
      setCurSentenceId(null);
    } else {
      setCurSentenceId(id);
    }
  };

  useUpdateEffect(() => {
    const curSentence = workSentences.find((item) => item.id === curSentenceId);
    if (curSentence) {
      setCurIllustrationId(curSentence.relId);
    } else {
      setCurIllustrationId(null);
    }
  }, [curSentenceId]);

  const onDelete = (sentenceId) => {
    let workSentence = getSentenceById(sentenceId);
    if (!workSentence) return;

    resetSelection();

    // removeIllustrationPart(workLine.relIllustrationId);
    removeIllustration(workSentence.relId);
    removeSentence(workSentence.id);
  };

  // !! hmm needs some thinkering, maybe set a state that the next sentence needs to be replaced
  const onReplace = (sentenceId) => {
    let workSentence = getSentenceById(sentenceId);

    // do something with the image
    const currentLineIndex = workSentences.findIndex((l) => l.id === sentenceId);

    showContentModal('sentence', currentLineIndex);
    // find something to make this smoother, i want the sentence to only be removed if the user
    // does not cancel the replace dialog. Maybe create some kind of stack of actions?
    removeIllustration(workSentence.relId);
    removeSentence(workSentence.id);

    //
    //showContentModal(type, currentLineIndex - 1);
  };

  const onAddBefore = (sentenceId, type = 'sentence') => {
    // // get the index of the line by line id, this is used to place
    // // the next line at the right position
    const currentLineIndex = workSentences.findIndex((l) => l.id === sentenceId);
    showContentModal(type, currentLineIndex);
  };
  const onAddAfter = (sentenceId, type = 'sentence') => {
    const currentLineIndex = workSentences.findIndex((l) => l.id === sentenceId);
    showContentModal(type, currentLineIndex + 1);
  };

  // -- Wizard Functions ---------------------------------------------------------------
  const onWizardContent = (data) => {
    setAutoScroll(false);
    switch (data.type) {
      case 'illustration':
        addIllustration(data.illustration);
        break;
      case 'sentence':
        addSentence(data.sentence);

        break;
      default:
    }
    // reset the previous content
    // console.log(data);
  };

  const closeWizardOverlay = () => {
    setShowWizard(false);
  };

  const openWizardOverlay = () => {
    resetWork();
    setShowWizard(true);
  };

  // -- Content Modal Functions --------------------------------------------------------
  // --------------------------------------------------------------------------------------- //
  const showContentModal = (type, nextPos = null) => {
    // slightly dirty but works for now
    setTimeout(() => {
      setCurSentenceId(null);
    }, 500);

    nextPos = nextPos === null ? workSentences.length + 1 : nextPos;
    setNextSentencePos(nextPos);

    setCurrentContentType(type);
    setShowContentOverlay(true);
  };

  const closeContentModal = () => {
    setShowContentOverlay(false);
  };

  const showIllustrationModal = () => {
    showContentModal('illustration');
  };

  const showSentenceModal = () => {
    showContentModal('sentence');
  };

  // receives the content from the content panel selection
  const onContent = (data) => {
    setAutoScroll(true);

    switch (data.type) {
      case 'illustration':
        addIllustration(data.illustration);
        break;
      case 'sentence':
        addSentence(data.sentence);

        break;
      default:
    }

    closeContentModal();
  };

  const testFn = useCallback(() => {}, [date]);
  // -- save the work when the user changes routes ---------------------------------------------------
  // const confirmSettings = {
  //   title: 'Je werk is nog niet opgeslagen! Wil je dit alsnog doen?',
  //   textCancel: 'opslaan',
  //   textProceed: 'nee',
  //   testFn: testFn
  // };

  const onRouteLeaveConfirm = useCallback(
    (done) => {
      testFn();
      // console.log(date);
      // do some saving... then finish
      // saveWork();
      done();
      // window.alert('implement saving... wil continue in 1000');
      // setTimeout(() => {
      //   done();
      // }, 1000);
    },
    [workSentences]
  );

  // this is the functionality which is used when the user leaves the page, it gives
  // the user the option to save the work
  useRouteChangeDialog(
    {
      type: 'confirm',
      text: 'Je werk is nog niet opgeslagen! Wil je dit alsnog doen?',
      textProceed: 'opslaan',
      textCancel: 'nee',
      onConfirm: () => {
        saveWork();
      },
      onCancel: () => {}
    },
    onRouteLeaveConfirm,
    workState.hasChanged
  );

  const startNewWork = async () => {
    openDialog({
      type: 'confirm',
      text: 'Je werk is nog niet opgeslagen! Wil je toch doorgaan?',
      textProceed: 'doorgaan',
      textCancel: 'nee',
      onConfirm: () => {
        // console.log('confirm');
        // saveWork();
        openWizardOverlay();
      },
      onCancel: () => {
        // openWizardOverlay();
      }
    });
  };

  // -- Generator functionality --------------------------------------------------------------- //
  const onGeneratorSelect = (sentences) => {
    stateResetWork();

    var work = {};
    setAutoScroll(false);
    setWorkColor(colors[Math.round(Math.random() * (colors.length - 1))]);

    // console.log(sentences);

    sentences.forEach((sentence, index) => {
      addSentence(sentence, nextSentencePos + index + 1);
    });

    setShowGeneratorOverlay(false);
  };

  // -- Listeners --------------------------------------------------------
  useListener('add_illustration', () => {
    showContentModal('illustration');
  });
  useListener('reset', startNewWork);
  useListener('add_sentence', () => {
    showContentModal('sentence');
  });

  // -- Work Tools --------------------------------------------------------
  const onWorkSave = async () => {
    await saveWork();

    openDialog({
      type: 'confirm',
      text: 'Je werk is opgeslagen.',
      textProceed: 'ok',
      disableCancel: true,
      onConfirm: () => {
        navigate('/dashboard');
      }
    });
  };

  const onWorkRemove = async () => {
    openDialog({
      type: 'confirm',
      text: 'Weet je zeker dat je dit werk wilt verwijderen?',
      textProceed: 'doorgaan',
      textCancel: 'nee',
      onConfirm: () => {
        workRemove(workState.workId);
        navigate('/dashboard');
      }
    });
  };

  const onWorkShare = async () => {
    setCurSentenceId(null);
    setCurIllustrationId(null);
    setAppLoading(true);
    // generate canvas image
    const imageDataUrl = await generatorCanvasClassRef.current.generateExport();
    const imageBlob = await (await fetch(imageDataUrl)).blob();

    const filesArray = [
      new File([imageBlob], 'ververs.jpg', {
        type: imageBlob.type,
        lastModified: new Date().getTime()
      })
    ];

    setAppLoading(false);

    const shareData = {
      title: 'ververs.app',
      text: 'Gemaakt met ver+vers.',
      // url: "https://ververs.app",
      files: filesArray
    };

    navigator.share(shareData).then((data) => {});
  };

  const onWorkDownload = async () => {
    setCurSentenceId(null);
    setCurIllustrationId(null);

    setAppLoading(true);

    let image = await generatorCanvasClassRef.current.generateExport();

    setTimeout(() => {
      setAppLoading(false);

      const a = document.createElement('a');
      a.href = image;
      a.download = 'ververs_' + date.getTime() + '.jpg';
      a.click();
    }, 1500);
  };

  // -- Playing timeline --------------------------------------------------------
  const timeline = useRef(null);
  const onWorkTimelinePlay = () => {
    const interval = 3;

    resetSelection();
    setIsPlaying(true);
    setNrProgressSections(workSentences.length);

    if (timeline.current) {
      timeline.current.kill();
    }

    // create a gsap timeline which is paused by default
    timeline.current = gsap.timeline({
      paused: true,
      duration: workState.sentences.length * interval,
      onComplete: () => {
        onWorkTimelineStop();
      },
      onUpdate: function (timeline) {
        gsap.set(progressRef.current, { scaleX: this.progress() });
        // setTimelineProgress(this.progress());
        // console.log('update');
      }
    });

    workState.sentences.forEach((sentence, index) => {
      timeline.current.add(() => {
        setPlayheadIllustrationId(sentence.relId);
        setPlayheadSentenceId(sentence.id);
      }, index * interval);
    });

    // timeline.current.add(() => {
    //   console.log('a');
    // }, 1);
    // timeline.current.add(() => {
    //   console.log('b');
    // }, 3);
    timeline.current.play();
    // console.log('work timeline play');
  };

  useEffect(() => {
    if (!isPlaying) {
      onWorkTimelineStop();
    }
  }, [isPlaying]);

  const onWorkTimelineStop = () => {
    // resetSelection();
    if (timeline.current) {
      timeline.current.kill();
      setTimelineProgress(0);
    }
    setIsPlaying(false);
    setNrProgressSections(null);
  };

  const resetSelection = () => {
    setCurSentenceId(null);
    setCurIllustrationId(null);
  };

  // determine the allowed layers for the next illustration
  useEffect(() => {
    // setAllowedLayers([2]);
  }, [workIllustrations]);

  if (!illustrationComposer) {
    return;
  }

  return (
    <div className="generator__page">
      <div className="generator__canvas">
        <GeneratorCanvas
          layers={workIllustrations}
          thumbGeneratedCb={() => {}}
          ref={generatorCanvasClassRef}
          gridSize={gridSize}
          onPosChangeCb={illustrationChangePos}
          onScaleChangeCb={illustrationChangeScale}
          curIllustrationId={curIllustrationId}
          resetSelection={resetSelection}
          isPlaying={isPlaying}
          playheadIllustrationId={playheadIllustrationId}
        />
      </div>
      <div className="generator__interface">
        <div className="generator__tools ">
          <GeneratorToolbar
            onWorkSave={onWorkSave}
            onWorkRemove={onWorkRemove}
            onWorkShare={onWorkShare}
            onWorkDownload={onWorkDownload}
            onWorkTimelinePlay={onWorkTimelinePlay}
            onWorkTimelineStop={onWorkTimelineStop}
            progress={timelineProgress}
            progressSections={nrProgressSections}
            isPlaying={isPlaying}
            ref={progressRef}></GeneratorToolbar>
        </div>
        <div className="generator__body ">
          <GeneratorWorkSentences
            sentences={workSentences}
            curSentenceId={curSentenceId}
            autoScroll={autoScroll}
            onSelect={onSelect}
            onDelete={onDelete}
            onReplace={onReplace}
            onAddBefore={onAddBefore}
            onAddAfter={onAddAfter}
            isPlaying={isPlaying}
            playheadSentenceId={playheadSentenceId}></GeneratorWorkSentences>
        </div>
      </div>

      <StartNew
        visible={showWizard}
        onClose={closeWizardOverlay}
        onContentSelect={onWizardContent}
        allowedLayers={illustrationComposer.nextLayer()}></StartNew>

      <AddContentOverlay
        visible={showContentOverlay}
        type={currentContentType}
        onSelect={onContent}
        onClose={closeContentModal}></AddContentOverlay>

      <GeneratorOverlay visible={showGeneratorOverlay} onSelect={onGeneratorSelect}></GeneratorOverlay>
      {/* the sentences renderer */}
    </div>
  );
}

export default Generator;
