/**
 * These hooks re-implement the now removed useBlocker and usePrompt hooks in 'react-router-dom'.
 * Thanks for the idea @piecyk https://github.com/remix-run/react-router/issues/8139#issuecomment-953816315
 * Source: https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874#diff-b60f1a2d4276b2a605c05e19816634111de2e8a4186fe9dd7de8e344b65ed4d3L344-L381
 */
import { useContext, useEffect, useCallback } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { useDialogConfirm } from '../hooks/useDialogConfirm';
import { useDialog } from '../components/Dialog/DialogProvider';

/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 *
 * @param  blocker
 * @param  when
 * @see https://reactrouter.com/api/useBlocker
 */
export function useBlocker(blocker, when = true) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const unblock = navigator.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        }
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}

/**
 * Prompts the user with an Alert before they leave the current screen.
 * !!! needs a fix:
 *!!!! https://github.com/Bilal-Bangash/detecting-route-change-react-route-dom-v6/blob/master/src/hooks/useCallbackPrompt.ts
 * @param  message
 * @param  when
 */

export function useRouteChangeDialog(dialogSettings, onConfirm, when = true) {
  const [openDialog, closeDialog] = useDialog();
  const { isConfirmed } = useDialogConfirm();

  const blocker = useCallback(
    (tx) => {
      // slightly adjust the dialog settings to allow for hooking into
      // the confirm and cancel functions.
      const adjustedSettings = {
        ...dialogSettings,
        onConfirm: () => {
          const done = () => {
            tx.retry();
          };
          dialogSettings.onConfirm(done);
          if (!done) tx.retry();

          tx.retry();
        },
        onCancel: (done) => {
          dialogSettings.onCancel();
          if (!done) tx.retry();

          tx.retry();
        }
      };
      // open the dialog
      openDialog(adjustedSettings);
    },
    [dialogSettings, openDialog]
  );

  useBlocker(blocker, when);
}
