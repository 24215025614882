//https://blog.logrocket.com/build-modal-with-react-portals/
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import Overlay from '../Overlay';
import { Transition } from 'react-transition-group';
import { useRef } from 'react';
import gsap from 'gsap';
import SelectIllustration from './SelectIllustration';
import SelectSentence from './SelectSentence';

import ChoiceList from '../ChoiceList';

import useStore from '../../store/store';

function getMatchingSentencesByComposeCat(items, cats, excl = []) {
  let matchedSentences = items
    .map((item) => {
      // console.log(item);
      let notAlreadyUsed = excl.indexOf(item.id) === -1;
      let intersect = item.composeCategories.filter((cat) => cats.includes(cat));
      if (intersect.length && notAlreadyUsed) {
        return {
          matches: intersect.length,
          sentence: item
        };
      }
      return false;
    })
    .filter((item) => item);

  return matchedSentences;
}

function CategoryGroup({ selected, label, choices, onSelect }) {
  const listChoices = choices.map((choice) => {
    return {
      id: choice.id,
      label: choice.title
    };
  });

  return (
    <div className="category-group">
      <h3>{label}</h3>

      <div className="category-group__choices">
        <ChoiceList choices={listChoices} selected={selected} wildcardLabel={false} defaultSelected={null} onSelect={onSelect} />
      </div>
    </div>
  );
}
function AddContentOverlay({ visible, type, onClose, onSelect }) {
  const [selectedChoices, setSelectedChoices] = useState({});

  const sentences = useStore((state) => state.sentences);
  const composerCats = useStore((state) => state.composerCats);

  const onContentSelect = () => {
    const nrOfSentences = Math.floor(Math.random() * 3) + 5;

    const selectedCategories = Object.values(selectedChoices);

    // walk throug each category and get the sentences which match the category
    // composerCats.forEach((cat) => {
    //   let selected = selectedChoices[cat.id];
    //   let matches = getMatchingSentencesByComposeCat(sentences, [selected]);
    //   matches = matches.sort((a, b) => 0.5 - Math.random());
    //   console.log(matches);
    // });

    const matches = getMatchingSentencesByComposeCat(sentences, selectedCategories);

    let shuffledMatches = matches.sort((a, b) => 0.5 - Math.random());
    let orderedByMatches = shuffledMatches.sort((a, b) => b.matches - a.matches);
    let matchedSentences = orderedByMatches.map((item) => item.sentence);
    // console.log(items);

    // console.log(selectedCategories);
    // return;
    // // get a set of random sentences from the sentences array
    // const shuffledSentences = matchedSentences.sort((a, b) => 0.5 - Math.random());
    const selectedSentences = matchedSentences.slice(0, nrOfSentences);

    onSelect(selectedSentences);
    // onSelect({ type, ...data });
  };

  const onOptionSelect = (groupId, optionId) => {
    let currentChoices = selectedChoices;
    currentChoices[groupId] = optionId;

    setSelectedChoices({ ...currentChoices });
  };

  // tmp disbaled
  // const isReady = Object.keys(selectedChoices).length === composerCats.length;
  const isReady = Object.keys(selectedChoices).length >= 1;
  return (
    <Overlay isVisible={visible} overlayType={'full'} handleClose={onClose}>
      <div className="generator-overlay container">
        <div className="generator-overlay__header">
          <h1>Genereer een werk.</h1>
        </div>
        <div className="generator-overlay__content">
          {composerCats.map((group, index) => (
            <CategoryGroup
              key={`composer-group-${index}`}
              label={group.title}
              id={group.id}
              selected={selectedChoices[group.id]}
              slug={group.slug}
              choices={group.children}
              onSelect={(optionId) => onOptionSelect(group.id, optionId)}></CategoryGroup>
          ))}
        </div>

        <div className="generator-overlay__footer">
          {isReady && (
            <button className={`pill-button pill-button--large is-primary`} onClick={onContentSelect}>
              genereer
            </button>
          )}
        </div>
      </div>
    </Overlay>
  );
}

export default AddContentOverlay;
