import { PopupMenu } from '../components/Popup/PopupMenu';
import { PopupColor } from '../components/Popup/PopupMenu';

const IconButton = ({ className = '', popup = 'menu', popupMenuPosition = 'top', popupMenu, onClick, Icon }, ref) => {
  const PopupComponent = popup === 'menu' ? PopupMenu : PopupColor;

  const Popup = ({ children }) => {
    if (popup === 'menu') {
      return (
        <PopupMenu options={popupMenu} onMenuClick={onClick} position={popupMenuPosition}>
          {children}
        </PopupMenu>
      );
    }

    if (popup === 'color') {
      return <PopupColor>{children}</PopupColor>;
    }
  };

  return popupMenu ? (
    <Popup>
      <button className={`icon-button ${className}`}>
        <Icon />
      </button>
    </Popup>
  ) : (
    <button className={`icon-button ${className}`} onClick={onClick}>
      <Icon />
    </button>
  );
};

export default IconButton;
