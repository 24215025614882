import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { useBus } from 'react-bus';

import gsap from 'gsap';

import { ReactComponent as IconNanoCross } from '../../assets/icons/icon-nano-cross.svg';
import { ReactComponent as IconNanoDots } from '../../assets/icons/icon-nano-dots.svg';
import { ReactComponent as IconLineAdd } from '../../assets/icons/icon-line-add.svg';
import { ReactComponent as IconLineOptions } from '../../assets/icons/icon-line-options.svg';

import { PopupMenu } from '../Popup/PopupMenu';
import gsapCore from 'gsap/gsap-core';

import loaderAnimation from '../../assets/animations/token-1.json';
import Lottie from 'react-lottie-player';

const TokenButton = ({ token, onClick, isCollected }) => {
  if (!token) return;
  // const TokenStyle = token.tokenStyle;
  return (
    <button className={`token ${isCollected ? 'is-collected' : ''}`} onClick={onClick}>
      <div>
        <Lottie loop animationData={token.tokenStyle} play={!isCollected} style={{ width: '100%', height: '100%' }} />

        {/* <TokenStyle></TokenStyle> */}
      </div>
    </button>
  );
};

// -- SentenceOption ------------------------------------------------------
const SentenceOption = (props) => {
  const refPopupMenu = useRef(null);

  return (
    <>
      <PopupMenu options={props.popupMenu} onMenuClick={props.onMenuClick}>
        <button>{props.type === 'option' ? <IconLineOptions /> : <IconLineAdd />}</button>
      </PopupMenu>
    </>
  );
};

// -- ComposeLine ------------------------------------------------------
const ComposeLine = ({ part }) => {
  const [eggDiscovered, setEggDiscovered] = useState(false);
  const easterEgg = part.filter((el) => el.classList.contains('token'));
  const text = part.filter((el) => !el.classList.contains('.token')).map((i) => i.textContent);

  const onToggle = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setEggDiscovered(true);
  };

  if (text === '') return;

  return (
    <>
      <span>
        {text.map((el, index) => (
          <span key={`composeline-index-${index}`}>{el}</span>
        ))}
      </span>
    </>
  );
};

function GeneratorSentence(
  {
    text,
    sentenceId,
    animate,
    onSelect,
    onLineClick,
    isNew,
    sentencePosition,
    isActive,
    tokenCollected,
    onDelete,
    onAddAfter,
    onEdit,
    onReplace,
    onAddBefore,
    token,
    tokenCollectd,
    onTokenCollect,
    renderTrigger,
    onEggCollect,
    nrOfEggsFound
  },
  ref
) {
  const [parts, setParts] = useState([]);

  const elRef = useRef(null);
  const elAfterRef = useRef(null);
  const refHasAppeared = useRef(null);
  const refPrevSentencePos = useRef(null);
  const bus = useBus();

  const menuOptions = [
    {
      label: 'Bewerk beeld',
      onClick: () => {
        bus.emit('edit-illustration');
      }
    },
    {
      label: 'Ververs zin',
      onClick: onReplace
    },
    {
      label: 'Verwijder zin',
      onClick: onDelete
    }
  ];

  const menuAddAfterOptions = [
    {
      label: 'Zin',
      onClick: (ev) => {
        onAddAfter('sentence');
      }
    },
    {
      label: 'Illustratie',
      onClick: (ev) => {
        onAddAfter('illustration');
      }
    }
  ];

  const menuAddBeforeOptions = [
    {
      label: 'Zin',
      onClick: () => {
        onAddBefore('sentence');
      }
    },
    {
      label: 'Illustratie',
      onClick: () => {
        onAddBefore('illustration');
      }
    }
  ];

  // -- text splitting into parts ---------------------------------------------------

  const makeParts = () => {
    const el = elRef.current;
    const elAfter = elAfterRef.current;
    if (!el) return;

    // ----------------
    // walk throug all children and add padding if is the first of a line or the
    // the first of a new line
    let prevY;
    let childArray = Array.from(el.children);
    let childTextsArray = Array.from(el.querySelectorAll('span'));

    // let childArray = Array.from(el.querySelectorAll('span'));
    for (const child of childArray) {
      const y = child.getBoundingClientRect().top;
      if (prevY == null) prevY = y;
      let index = childTextsArray.indexOf(child);
      let isFirstChild = childTextsArray.indexOf(child) === 0;
      let isLastChild = childTextsArray.indexOf(child) === childTextsArray.length - 1;
      let isFirstOnNewLine = 0;
      let isLastOnLine = 0;
      let isToken = child.tagName === 'BUTTON';

      if (!isToken) {
        if (prevY !== y) {
          isFirstOnNewLine = true;
        }
        if (isFirstChild || isFirstOnNewLine) {
          child.classList.add('left-padding');
        }
        if (isLastChild) {
          child.classList.add('right-padding');
        }

        // handle the last of a line
        if (prevY !== y && index > 0) {
          let prevChild = childTextsArray[index - 1];
          prevChild.classList.add('right-padding');
        }
      }

      prevY = y;
    }

    // ----------------
    // now we will check these same lines and put them in seperate line parts for coloring
    // and interface purposes
    let lastY;
    let newLines = [];
    let words = [];

    // check for each child if the y position differs, if that is true
    // add it to a new line
    for (const child of Array.from(el.children)) {
      if (child.tagName !== 'SPAN') continue;
      // console.log(child);
      const y = child.getBoundingClientRect().top;
      if (lastY == null) lastY = y;
      if (y !== lastY) {
        newLines.push(words);
        words = [];
      }
      lastY = y;
      words.push(child);
    }

    newLines.push(words);

    setParts(newLines);
  };

  // if sentence position changes update parts
  useEffect(() => {
    if (refPrevSentencePos.current === null) {
      refPrevSentencePos.current = sentencePosition;
      return;
    }
  }, [sentencePosition]);

  useLayoutEffect(() => {
    if (!parts.length) {
      makeParts();
    }
  }, [parts]);

  // if a part is the last on the line, make it fit to right side of the screen
  useLayoutEffect(() => {
    if (parts.length) {
      const lineParts = elAfterRef.current.querySelectorAll('.line__part');
    }
  }, [parts]);

  // highlight newly added lines
  useLayoutEffect(() => {
    if (animate && elAfterRef.current) {
      const lineParts = elAfterRef.current.querySelectorAll('.line__part');
      // gsap.set(lineParts, { opacity: 0 });

      setTimeout(() => {
        elAfterRef.current.classList.add('is-new');
      }, 10);

      setTimeout(() => {
        elAfterRef.current.classList.remove('is-new');
      }, 1250);
      // lineParts.forEach((part) => {
      //   part.classList.add('is-new');
      // });
      // gsap.fromTo(
      //   lineParts,
      //   { opacity: 0, backgroundColor: 'black' },
      //   {
      //     delay: 0.2,
      //     opacity: 1,
      //     duration: 1,
      //     onComplete: () => {
      //       gsap.set(lineParts, { clearProps: 'backgroundColor' });
      //     }
      //   }
      // );
      // gsap.to(lineParts, { clearProps: 'backgroundColor' });
    }
  }, [parts, animate]);

  const splittedText = text.split(' ');

  const onTokenClick = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (token !== null) {
      onTokenCollect(token.tokenId);
    }
  };

  return parts.length ? (
    <div
      className={`line is-splitted ${isActive ? 'is-active' : ''}`}
      ref={elAfterRef}
      onClick={onSelect}
      data-sentence-id={sentenceId}>
      {isActive && (
        <span className="line-controls line-controls--before">
          <span className="line-controls__inner">
            <SentenceOption label="+" type="add" popupMenu={menuAddBeforeOptions} />
          </span>
        </span>
      )}

      <div className="line__wrapper">
        {parts.map((part, index) => (
          <div key={`line-part-${index}`} className="line__part">
            <ComposeLine part={part} />
          </div>
        ))}
        {token && (
          <div className="line__part line__part--token">
            <TokenButton onClick={onTokenClick} token={token} isCollected={tokenCollected}></TokenButton>
          </div>
        )}

        {/* {lineEgg && <span className="token"></span>} */}
      </div>

      {isActive && (
        <span className="line-controls line-controls--after">
          <span className="line-controls__inner">
            <SentenceOption label="..." type="option" popupMenu={menuOptions} />
            <SentenceOption label="+" type="plus" popupMenu={menuAddAfterOptions} />
          </span>
        </span>
      )}

      {/* <div className="line__part">
          <SentenceOption label="+" type="plus" popupMenu={menuAddBeforeOptions}></SentenceOption>
          <SentenceOption label="+" type="plus" popupMenu={menuAddAfterOptions}></SentenceOption>
          <SentenceOption label="..." type="option" popupMenu={menuOptions}></SentenceOption>

          {text}
        </div> */}
    </div>
  ) : (
    <div className="line" ref={elRef}>
      {splittedText.map((word, index) => (
        <span key={`line-${index}`}>{`${word}`}</span>
      ))}
      {token && <TokenButton></TokenButton>}
    </div>
  );
}

export default GeneratorSentence;

/*
     <div className={`line is-splitted ${isActive ? 'is-active' : ''}`} ref={elAfterRef} onClick={onSelect}>
      <div className="line__wrapper">
        {parts.map((part, index) => (
          <div key={`line-part-${index}`} className="line__part">
            <ComposeLine part={part} />
          </div>
        ))}
        </div>

        {isActive && (
          <span className="line-controls line-controls--after">
            <span className="line-controls__inner">
              <SentenceOption label="..." type="option" popupMenu={menuOptions} />
              <SentenceOption label="+" type="plus" popupMenu={menuAddAfterOptions} />
            </span>
          </span>
        )}
  
  
      </div>
      */
