import { useQuery } from '@apollo/client';

import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useStore from '../store/store';
import gsap from 'gsap';

import { GET_LINES, GET_ILLUSTRATIONS, GET_CAT_THEMES, GET_CAT_COMPOSER, GET_TOKENS } from '../common/Queries';

function Sentence({ sentence }) {
  const [parts, setParts] = useState([]);
  const elRef = useRef(null);
  const splittedText = sentence.split(' ');

  const makeParts = () => {
    const el = elRef.current;
    if (!el) return;

    // ----------------
    // walk throug all children and add padding if is the first of a line or the
    // the first of a new line
    let prevY;
    let childArray = Array.from(el.children);
    let childTextsArray = Array.from(el.querySelectorAll('span'));

    // let childArray = Array.from(el.querySelectorAll('span'));
    for (const child of childArray) {
      const y = child.getBoundingClientRect().top;
      if (prevY == null) prevY = y;
      let index = childTextsArray.indexOf(child);
      let isFirstChild = childTextsArray.indexOf(child) === 0;
      let isLastChild = childTextsArray.indexOf(child) === childTextsArray.length - 1;
      let isFirstOnNewLine = 0;
      let isLastOnLine = 0;
      let isToken = child.tagName === 'BUTTON';

      if (!isToken) {
        if (prevY !== y) {
          isFirstOnNewLine = true;
        }
        if (isFirstChild || isFirstOnNewLine) {
          child.classList.add('left-padding');
        }
        if (isLastChild) {
          child.classList.add('right-padding');
        }

        // handle the last of a line
        if (prevY !== y && index > 0) {
          let prevChild = childTextsArray[index - 1];
          prevChild.classList.add('right-padding');
        }
      }

      prevY = y;
    }

    // ----------------
    // now we will check these same lines and put them in seperate line parts for coloring
    // and interface purposes
    let lastY;
    let newLines = [];
    let words = [];

    // check for each child if the y position differs, if that is true
    // add it to a new line
    for (const child of Array.from(el.children)) {
      if (child.tagName !== 'SPAN') continue;
      // console.log(child);
      const y = child.getBoundingClientRect().top;
      if (lastY == null) lastY = y;
      if (y !== lastY) {
        newLines.push(words);
        words = [];
      }
      lastY = y;
      words.push(child);
    }

    newLines.push(words);

    setParts(newLines);
  };

  // if a part is the last on the line, make it fit to right side of the screen
  useLayoutEffect(() => {
    if (!parts.length) {
      makeParts();
    }
  }, [parts]);

  return parts.length ? (
    <div className={`line is-splitted`}>
      <div className="line__wrapper">
        {parts.map((part, index) => {
          const text = part.map((i) => i.textContent);
          if (text === '') return;

          return (
            <div key={`line-part-${index}`} className="line__part">
              <span>
                {text.map((el, index) => (
                  <span key={`composeline-index-${index}`}>{el}</span>
                ))}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <div className="line" ref={elRef}>
      {splittedText.map((word, index) => (
        <span key={`line-f-${index}`}>{`${word}`}</span>
      ))}
    </div>
  );
}

function SentencesRenderer({ sentences }) {
  const wrapperRef = useRef(null);

  // fill the gaps for al sentences which do not reach till the end of the screen
  useLayoutEffect(() => {
    const wrapperWidth = wrapperRef.current.clientWidth;
    const wrapperOffset = wrapperRef.current.getBoundingClientRect().left;
    // console.log(wrapperRef.current.clientWidth);

    if (sentences.length) {
      setTimeout(() => {
        let allParts = Array.from(wrapperRef.current.querySelectorAll('.line__part'));

        gsap.set(allParts, { clearProps: 'width' });
        let sentencePositions = allParts.map((part) => {
          return { el: part, top: Math.round(part.getBoundingClientRect().top) };
        });

        if (!sentencePositions.length) return;

        // find the last sentence part of each row
        var groupedByTop = sentencePositions.reduce((group, part) => {
          const { top } = part;
          group[top] = group[top] ?? [];
          group[top].push(part);
          return group;
        }, {});

        var lastParts = Object.entries(groupedByTop)
          .slice(0, -1)
          .map(([top, parts]) => {
            let part = parts.pop();
            let bound = part.el.getBoundingClientRect();

            let newWidth = 0;

            if (bound.x - wrapperOffset + bound.width < wrapperWidth) {
              newWidth = wrapperWidth - bound.x;
            }

            return {
              el: part.el,
              top: part.top,
              newWidth: newWidth
            };
          })
          .filter((part) => part.newWidth > 0);

        lastParts.forEach((part) => {
          part.el.classList.add('extend');
          // gsap.set(part.el, { width: part.newWidth - 2 });
        });

        // create
      }, 0);
    }
  }, [sentences, wrapperRef]);

  return (
    sentences && (
      <div className="sentences-renderer generator-worklines">
        <div className="sentences-renderer__inner js-sentences-renderer" ref={wrapperRef}>
          {sentences.map((sentence, index) => {
            return <Sentence key={`sentence-${index}`} sentence={sentence.text} />;
          })}
          <span className="ververs-logo">
            <span>ververs.app</span>
          </span>
        </div>
      </div>
    )
  );
}

export default SentencesRenderer;
