//https://blog.logrocket.com/build-modal-with-react-portals/
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import Overlay from '../Overlay';
import { Transition } from 'react-transition-group';
import { useRef } from 'react';
import gsap from 'gsap';
import useStore from '../../store/store';
import useWorkStore from '../../store/workStore';

import ThemeSelector from './ThemeSelector';

function IllustrationTile({ illustration, onSelect }) {
  const [isLoaded, setIsLoaded] = useState(false);

  const orientation = illustration.image.previewAspect < 0.8 ? 'landscape' : 'portrait';
  return (
    <>
      <div
        className={`illustration-list__tile illustration-tile ${isLoaded ? 'is-loaded' : ''} is-${orientation} `}
        onClick={() => onSelect(illustration)}>
        <div className="illustration-tile__image">
          <figure>
            <img
              width={illustration.image.previewWidth}
              height={illustration.image.previewHeight}
              src={illustration.image.previewUrl}
              onLoad={() => setIsLoaded(true)}
              alt=""
            />
          </figure>
          <div className="illustration-tile__loader"></div>
        </div>
        {/* <div className="illustration-tile__id"> {illustration.id}</div> */}
      </div>
    </>
  );
}
// ---------------------------------------------------------
function SelectIllustration({ onSelect, allowedLayers = [] }) {
  const themes = useStore((state) => state.themes);
  const workIllustrations = useWorkStore((state) => state.illustrations);
  const [currentTheme, setCurrentTheme] = useState(null);
  const illustrations = useStore((state) => state.illustrations);
  const [filteredIllustrations, setFilteredIllustrations] = useState([]);
  const nrOfIllustrations = 6;
  const [selectedIllustration, setSelectedIllustration] = useState(null);
  const scrollRef = useRef(null);

  const usedIllustrations = useMemo(() => {
    if (!workIllustrations) return [];
    return workIllustrations.map((layer) => layer.illustration.id);
  }, [workIllustrations]);

  const onClickSelect = () => {
    onSelect({ illustration: selectedIllustration });
  };

  const selectIllustration = (illustration) => {
    setSelectedIllustration(illustration);
  };

  const toggleTheme = (theme) => {
    if (currentTheme === theme) {
      setCurrentTheme(null);
    } else {
      setCurrentTheme(theme);
    }
  };

  const getIllustrations = (limit = 0, exclude = []) => {
    let filteredIllustrations = illustrations;
    // filter the theme
    if (currentTheme) {
      filteredIllustrations = illustrations.filter((line) => {
        if (!line.themes) return false;

        return line.themes.indexOf(currentTheme) > -1;
      });
    }

    // filter the lines which are already used
    if (usedIllustrations.length) {
      filteredIllustrations = filteredIllustrations.filter((ill) => {
        return usedIllustrations.indexOf(ill.id) === -1;
      });
    }

    // filter the lines which are already shown in the viewing
    if (exclude.length > 0) {
      filteredIllustrations = filteredIllustrations.filter((ill) => {
        return exclude.indexOf(ill.id) === -1;
      });
    }

    console.log(allowedLayers);
    // if this is the first or second layer only show backgrounds
    // maybe this functionality should not be he here, but in an overarching component
    if (allowedLayers.length > 0) {
      // console.log(allowedLayers);
      filteredIllustrations = filteredIllustrations.filter((ill) => {
        const matchingLayers = ill.image.layer.filter((element) => allowedLayers.includes(element));
        // console.log(ill.image.layer.filter((element) => { return true}));
        // return false;
        return matchingLayers.length > 0;
      });
      // console.log(filteredIllustrations);
    }
    // if (workLines.length < 2) {
    //   filteredIllustrations = filteredIllustrations.filter((line) => {
    //     return line.layer === 0;
    //   });
    // }
    // shuffle array
    // var shuffledArray = [...filteredIllustrations];
    var shuffledArray = [...filteredIllustrations].sort((a, b) => 0.5 - Math.random());

    if (limit) {
      shuffledArray = shuffledArray.slice(0, limit);
    }
    return shuffledArray;
  };

  const loadMore = () => {
    let shownImages = filteredIllustrations.map((ill) => ill.id);
    // console.log(shownImages.length);
    let newImages = getIllustrations(nrOfIllustrations, shownImages);
    setFilteredIllustrations([...filteredIllustrations, ...newImages]);
  };

  useEffect(() => {
    scrollRef.current.scrollTop = 0;

    setSelectedIllustration(null);
    setFilteredIllustrations(getIllustrations(nrOfIllustrations));
  }, [currentTheme]);

  const handleScroll = useCallback(
    (ev) => {
      const loadOffset = 100;

      // console.log(ev.currentTarget.scrollTop);
      // console.log(ev.currentTarget.offsetHeight);
      // console.log(ev.currentTarget.scrollHeight);
      // console.log(ev.currentTarget.scrollTop + ev.currentTarget.offsetHeight);
      if (ev.currentTarget.scrollTop + ev.currentTarget.offsetHeight >= ev.currentTarget.scrollHeight - loadOffset) {
        loadMore();
      }
      // if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
    },
    [filteredIllustrations, loadMore]
  );
  useEffect(() => {
    let observerRefValue = null; // <-- variable to hold ref value
    if (scrollRef.current) {
      observerRefValue = scrollRef.current;
    }
    if (!observerRefValue) return;
    observerRefValue.addEventListener('scroll', handleScroll);
    return () => {
      observerRefValue.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, scrollRef]);

  // console.log(filteredIllustrations);

  return (
    <div className="select-content">
      <div className="select-content__header">
        <ThemeSelector themes={themes} currentTheme={currentTheme} onReset={() => setCurrentTheme(null)} onClick={toggleTheme} />
      </div>

      <div className="select-content__body">
        <div className="illustration-selection" ref={scrollRef}>
          <ul className="illustration-list">
            {filteredIllustrations.map((illustration, index) => (
              <li
                className={`illustration-list__item ${selectedIllustration === illustration ? 'is-active' : ''}`}
                key={`tile-${index}-${illustration.id}`}>
                <IllustrationTile illustration={illustration} onSelect={selectIllustration} />

                {/* <img
              src={process.env.REACT_APP_DOMAIN + illustration.image.url}
              alt=""
            /> */}
              </li>
            ))}
          </ul>
          <div className="illustration-selection__load">
            {/* <button className="pill-button pill-button--filled" onClick={loadMore}>
              laad meer
            </button> */}
          </div>
        </div>
      </div>

      {selectedIllustration && (
        <div className="select-content__footer">
          <button className="pill-button pill-button--large is-primary" onClick={onClickSelect}>
            Plaatsen
          </button>
        </div>
      )}
    </div>
  );
}

export default SelectIllustration;
