import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import GeneratorCanvas from '../components/Generator/GeneratorCanvas';
import GeneratorToolbar from '../components/Generator/GeneratorToolbar';
// import { Provider as BusProvider, useBus, useListener } from 'react-bus';
// import AddContent from '../components/AddContent';
import useStore from '../store/store';
import useWorkStore from '../store/workStore';
// import WorklinesExperiment from '../components/WorklinesExperiment';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as IconAddLarge } from '../assets/icons/icon-add.svg';
import gsap from 'gsap';
import TokenOverlay from '../components/Overlays/TokenOverlay';
import PopupMenu from '../components/Popup/PopupMenu';
import PageColofon from '../components/Overlays/PageColofon';
import PageHelp from '../components/Overlays/PageHelp';
import { ReactComponent as IconOptions } from '../assets/icons/icon-options.svg';
import { ReactComponent as IconArrowDown } from '../assets/icons/icon-arrow-down.svg';
import { ReactComponent as IconPlus } from '../assets/icons/icon-plus.svg';
import useContainerDrag from '../hooks/useContainerDrag';

import IconButton from '../components/IconButton';

// -- Stats Label ---------------------------------------------------------
function StatsLabel(props) {
  const { value } = props;
  return (
    <div className={`stat-label ${props.size ? 'size--' + props.size : ''}`}>
      <span>{value}</span>
    </div>
  );
}

// -- Progress Bar ---------------------------------------------------------
function ProgressBar(props) {
  const { value } = props;
  return (
    <div className="progress-bar">
      <div className="progress-bar__fill" style={{ width: `${value}%` }}></div>
    </div>
  );
}

// -- Tokens Panel --------------------------------------------------------
function TokensPanel(props) {
  const [isExpanded, setIsExpanded] = useState(false);
  const refPanelInner = useRef(null);

  const [showTokenContent, setShowTokenContent] = useState(false);
  const [currentToken, setCurrentToken] = useState(null);
  // useLayoutEffect(() => {
  //   console.log('gooo');
  //   if (!refPanelInner.current) return;
  //   const bounds = refPanelInner.current.getBoundingClientRect();
  //   const innerFullHeight = bounds.height;
  //   console.log(bounds);
  //   // gsap.set(refPanelInner.current, { height: innerFullHeight });
  //   // if (isExpanded) {
  //   //   gsap.to(refPanelInner.current, { height: innerFullHeight });
  //   // } else {
  //   //   gsap.to(refPanelInner.current, { height: 0 });
  //   // }
  // }, [isExpanded, refPanelInner]);
  const onTokenClick = (tokenId) => {
    setCurrentToken(tokenId);
    setShowTokenContent(true);
  };

  // console.log(props.collectedTokens);

  return (
    <>
      <div className={`tokens-panel ${props.collectedTokens.length <= 0 ? 'no-tokens' : ''}`}>
        <header className="tokens-panel__header">
          <StatsLabel value={props.collectedTokens.length}></StatsLabel>
          {props.collectedTokens.length === 1 ? <h3>Token gevonden</h3> : <h3>Tokens gevonden</h3>}

          <ProgressBar value={(props.collectedTokens.length / props.totalTokens) * 100}></ProgressBar>
        </header>
        <div className="tokens-panel__content tokens-panel-content">
          {isExpanded && (
            <div className="tokens-panel-content__inner" ref={refPanelInner}>
              <ul>
                {props.collectedTokens.map((token, index) => {
                  const title = [token.creator, token.title].filter((item) => item).join(', ');
                  return (
                    <li
                      key={`egg-` + index}
                      onClick={() => {
                        onTokenClick(token.id);
                      }}>
                      <figure
                        style={{
                          aspectRatio: token.embed.imageWidth / token.embed.imageHeight
                        }}>
                        <img src={token.embed.image} alt={token.title}></img>
                      </figure>
                      <span>{title}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        <div className="tokens-panel__toggle" onClick={() => setIsExpanded(!isExpanded)}>
          <IconButton className="" Icon={IconArrowDown} />
        </div>
      </div>
      <TokenOverlay visible={showTokenContent} tokenId={currentToken} onClose={() => setShowTokenContent(false)}></TokenOverlay>
    </>
  );
}

// -- Stats panel --------------------------------------------------------
function StatsSection(props) {
  return (
    <section className="stats-panel__row">
      <div>
        <StatsLabel value={props.value}></StatsLabel>
      </div>

      {props.value === 1 ? <p>{props.singularLabel}</p> : <p>{props.pluralLabel}</p>}
    </section>
  );
}

function StatsPanel(props) {
  // used sentences
  const usedSentences = useMemo(() => {
    const usedSentences = props.works.reduce((acc, work) => {
      return [...acc, ...work.sentences];
    }, []);
    return usedSentences;
  }, [props.works]);

  // unique sentences
  const uniqueSentences = useMemo(() => {
    return usedSentences.reduce((acc, sentence) => {
      if (!acc.find((s) => s.id === sentence.id)) {
        acc.push(sentence);
      }
      return acc;
    }, []);
  }, [usedSentences]);

  // re-used sentences
  const reUsedSentences = useMemo(() => {
    return usedSentences
      .map((item) => item.id)
      .reduce(function (list, item, index, array) {
        if (array.indexOf(item, index + 1) !== -1 && list.indexOf(item) === -1) {
          list.push(item);
        }
        return list;
      }, []);
  }, [usedSentences]);

  // count the duplicate sentences in array
  const stats = [
    { value: usedSentences.length, singularLabel: 'Gebruikte zin', pluralLabel: 'Gebruikte zinnen' },
    { value: uniqueSentences.length, singularLabel: 'Unieke zin', pluralLabel: 'Unieke zinnen' },
    { value: reUsedSentences.length, singularLabel: 'Zin is meermaals gebruikt', pluralLabel: 'Zinnen zijn meermaals gebruikt' }
  ];

  return (
    <>
      <div className="stats-panel">
        {stats.map((stat, index) => {
          return (
            <StatsSection
              key={`stat-${index}`}
              value={stat.value}
              singularLabel={stat.singularLabel}
              pluralLabel={stat.pluralLabel}></StatsSection>
          );
        })}
      </div>
    </>
  );
}

function WorksList(props) {
  const { onEdit, onAddNew, works } = props;
  const isMouseDevice = matchMedia('(hover:hover)').matches;
  const scrollerRef = useRef(null);
  const { handleMouseDown, handleMouseMove, handleMouseUp } = useContainerDrag(scrollerRef);

  return (
    <ul
      className={`works-list ${isMouseDevice ? 'use-mouse' : ''}`}
      ref={scrollerRef}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseUp}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}>
      <li className="works-list__add" key="23423423" onClick={onAddNew}>
        <IconPlus />
      </li>
      {works.map((work, index) => (
        <li key={index} onClick={() => onEdit(work)}>
          <figure>{work.thumb && <img src={work.thumb} alt="" />}</figure>
        </li>
      ))}
    </ul>
  );
}

// -- Dashboard ----------------------------------------------------------------------------------------------
function Dashboard() {
  const works = useStore((state) => state.works);
  const loadWork = useWorkStore((state) => state.loadWork);
  const tokens = useStore((state) => state.tokens);
  const collectedTokenIds = useStore((state) => state.collectedTokens);
  const collectedTokens = tokens.filter((token) => collectedTokenIds.includes(token.id));
  const setIsNotFirstUse = useStore((state) => state.setIsNotFirstUse);

  const [showColofon, setShowColofon] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  const navigate = useNavigate();
  const onEdit = (work) => {
    // loadWork(work);
    navigate('/generator', { state: { loadWork: work } });
  };

  const onAddNew = () => {
    navigate('/generator', { state: { showWizard: true } });

    // setTimeout(() => {
    //   bus.emit("add_sentence");
    // }, 0);
  };

  const menuOptions = [
    {
      label: 'Help',
      onClick: () => {
        setIsNotFirstUse(false);
        setTimeout(() => {
          navigate('/');
        }, 50);
      }
    },
    {
      label: 'Colofon',
      onClick: () => {
        setShowColofon(true);
      }
    }
  ];

  return (
    <>
      <div className="page-dashboard ">
        <div className="dashboard  container">
          <header className="dashboard__header">
            <IconButton className="" Icon={IconOptions} popup={'menu'} popupMenu={menuOptions} popupMenuPosition={'bottom'} />

            {/* <PopupMenu options={menuOptions} position={'bottom'}>
              <button className="">...</button>
            </PopupMenu> */}
          </header>
          {/*  */}
          <div>
            <div className="dashboard__section dashboard-section">
              <div className="dashboard-section__header">
                <h2>Eigen werken</h2>

                <StatsLabel value={works.length} size={'small'}></StatsLabel>
              </div>
              <div className="dashboard-section__content u-no-pad-t">
                <div className="works__section">
                  <WorksList works={works} onEdit={onEdit} onAddNew={onAddNew} />
                </div>
              </div>
            </div>

            <div className="dashboard__section dashboard-section">
              <div className="dashboard-section__header">
                <h2>Statistieken</h2>
              </div>
              <div className="dashboard-section__content ">
                <TokensPanel collectedTokens={collectedTokens} totalTokens={tokens.length}></TokensPanel>
                <StatsPanel works={works}></StatsPanel>
              </div>
            </div>
            {/* {currentEgg && (
          <Modal title="Easter egg" handleClose={closeModal}>
            <EasterEggContent eggId={currentEgg.id} />
          </Modal>
        )} */}
          </div>
        </div>
      </div>

      <PageHelp visible={showHelp} onClose={() => setShowHelp(false)}></PageHelp>
      <PageColofon visible={showColofon} onClose={() => setShowColofon(false)}></PageColofon>
    </>
  );
}

export default Dashboard;

//
// const addToEnd = useCallback(() => {
//   setNextLinePosition(workLines.length + 1);
//   showModal("text");
// }, [workLines]);
