import { useEffect, useState } from 'react';

import ChoiceList from '../ChoiceList';

function ThemeSelector({ themes, currentTheme, onReset, onClick }) {
  const choices = themes.map((theme) => {
    return {
      id: theme.id,
      label: theme.title
    };
  });

  const onSelect = (id) => {
    // onClick(id);
    // console.log(id);
    // onClick(id);
  };
  return (
    <div className="theme-selection">
      <ChoiceList
        choices={choices}
        selected={currentTheme}
        wildcardLabel={'alles'}
        defaultSelected={currentTheme}
        onSelect={onClick}
      />
      {/* <ul className="theme-list">
        <li className={`theme-list__item `}>
          <button className={`pill-button ${!currentTheme ? 'is-active' : ''}`} onClick={onReset}>
            Alles
          </button>
        </li>
        {themes.map(({ title, id }, index) => (
          <li className={`theme-list__item`} key={'theme-k-' + index}>
            <button className={`pill-button ${currentTheme === id ? 'is-active' : ''}`} onClick={() => onClick(id)}>
              {title}
            </button>
          </li>
        ))}
      </ul> */}
    </div>
  );
}

export default ThemeSelector;
