import * as htmlToImage from 'html-to-image';

// The illustration composer is responsible for the following:
// -
// -
// -
// -

export default class CompositionDrawer {
  constructor({ canvas, thumbCb, onLoaded, gridSize }) {
    this.defaultParams = {};
    this.gridSize = gridSize;

    this.nrCellRows = this.gridSize;
    this.nrCellCols = this.gridSize;

    this.layers = [];
    this.thumbGeneratedCb = thumbCb;

    this.onLoadedCallback = onLoaded;
    this.isLoading = true;

    // -- canvas
    this.renderCanvas = document.createElement('canvas');
    this.renderCanvasCtx = this.renderCanvas.getContext('2d');
    this.setupCanvas();
  }

  // -- Canvas ---------------------------------------------------------------------------------- //
  setupCanvas() {
    // Give the canvas pixel dimensions of their CSS
    // size * the device pixel ratio.
    this.renderCanvas.width = '1000';
    this.renderCanvas.height = '1000';

    this.nrCellRows = this.gridSize;
    this.nrCellCols = this.gridSize;

    this.cellWidth = this.renderCanvas.width / this.nrCellCols;
    this.cellHeight = this.renderCanvas.height / this.nrCellRows;
  }

  async generateExport() {
    this.drawCanvas();

    const loadImage = (path) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous'; // to avoid CORS if used with Canvas
        img.src = path;
        img.onload = () => {
          resolve(img);
        };
        img.onerror = (e) => {
          reject(e);
        };
      });
    };

    let textPart = await this.generateSentenceImg();
    let overlap = 50;
    let renderCanvasWidth = 1000;
    let renderCanvasHeight = 1000 + textPart.height - overlap;

    var renderCanvas = document.createElement('canvas');
    renderCanvas.width = renderCanvasWidth;
    renderCanvas.height = renderCanvasHeight;

    var renderCanvasCtx = renderCanvas.getContext('2d');
    renderCanvasCtx.fillStyle = 'white';
    renderCanvasCtx.fillRect(0, 0, renderCanvas.width, renderCanvas.height);
    renderCanvasCtx.drawImage(
      this.renderCanvas,
      0,
      0,
      this.renderCanvas.width,
      this.renderCanvas.height,
      0,
      0,
      renderCanvas.width,
      renderCanvas.width
    );

    // draw the text
    const textPartImg = await loadImage(textPart.base64);
    renderCanvasCtx.drawImage(textPartImg, 0, 1000 - overlap, textPart.width, textPart.height);

    return renderCanvas.toDataURL('image/jpeg', 1.0);
  }

  generateThumb(width = 200, height = 200) {
    this.drawCanvas();

    let thumbWidth = width;
    let thumbHeight = height;

    var thumbCanvas = document.createElement('canvas');
    thumbCanvas.width = thumbWidth;
    thumbCanvas.height = thumbHeight;

    var thumbCanvasCtx = thumbCanvas.getContext('2d');
    thumbCanvasCtx.fillStyle = 'white';
    thumbCanvasCtx.fillRect(0, 0, thumbCanvas.width, thumbCanvas.height);
    thumbCanvasCtx.drawImage(
      this.renderCanvas,
      0,
      0,
      this.renderCanvas.width,
      this.renderCanvas.height,
      0,
      0,
      thumbWidth,
      thumbHeight
    );

    var dataURL = thumbCanvas.toDataURL();
    return dataURL;
  }

  getLayer(layerId) {
    let results = this.layers.find((layer) => layer.id === layerId);

    return results;
  }

  drawCanvas() {
    // console.log('draw canvas');
    var renderLayers = [...this.layers];

    // deal with highlighted layers, these should be on top to be able to see them
    this.highlightedLayer = this.layers.find((layer) => layer.isHighlighted);
    // if (highlightedLayer) {
    //   // renderLayers = [...renderLayers.filter((layer) => !layer.isHighlighted), highlightedLayer];
    // }

    this.renderCanvasCtx.clearRect(0, 0, this.renderCanvas.width, this.renderCanvas.height);

    renderLayers
      .filter((item) => item.loaded)
      .forEach((item) => {
        // console.log(item);
        this.drawLayer(item);
      });

    // render the highlightedlayer last

    // let thumb = this.generateThumb();
    // this.thumbGeneratedCb(thumb);

    // this.drawGrid();
  }

  drawLayer(layer) {
    let renderWidth = this.cellWidth * layer.xSize;
    let renderHeight = renderWidth * layer.aspect;

    // if (renderHeight > )

    let yPos = layer.yPos * this.cellHeight;
    let xPos = layer.xPos * this.cellWidth;

    // when the image is destined for the bottom of the screen
    // make sure the image aligns to the bottom
    if (layer.yPos === this.gridSize - 1) {
      let diff = this.cellHeight - renderHeight;
      yPos = layer.yPos * this.cellHeight + diff;
    }

    // if (this.highlightedLayer && !layer.isHighlighted) {
    //   this.ctx.filter = 'grayscale(1)';
    //   this.ctx.globalAlpha = 0.2;
    // } else {
    //   this.ctx.filter = 'grayscale(0)';
    //   this.ctx.globalAlpha = 1;
    // }

    this.renderCanvasCtx.drawImage(layer.img, xPos, yPos, renderWidth, renderHeight);
    // // draw bounding box
    // this.ctx.beginPath();

    // this.ctx.restore();
    // this.ctx.lineWidth = 0.2;
    // this.ctx.strokeStyle = '#ff0000';
    // this.ctx.rect(xPos, yPos, renderWidth, renderHeight);

    // this.ctx.stroke();

    // console.log(renderWidth);
    // console.log(renderHeight);
  }
  loadImages() {
    let self = this;

    let imagesToLoad = this.layers.filter((layer) => !layer.loaded);
    if (!imagesToLoad.length) return;

    imagesToLoad.forEach((layer) => {
      var imageLoader = new Image();
      imageLoader.onload = function () {
        layer.loaded = true;
        // self.afterImageLoaded();
      };
      imageLoader.crossOrigin = '';
      imageLoader.src = layer.url;
      layer.img = imageLoader;
    });
  }

  setIllustration(layers) {
    let currentLayers = this.layers.map((layer) => layer.id);
    let newLayers = layers.map((layer) => layer.id);
    this.layers = [];
    // if (!layers.length) {
    //   this.layers = [];
    //   return;
    // }

    // check which items are removed
    let removedLayers = currentLayers.filter((layer) => !newLayers.includes(layer));
    this.layers = this.layers.filter((layer) => !removedLayers.includes(layer.id));

    layers.forEach((layer) => {
      // check if image is already set
      // let layerIndex = this.layers.find((l) => l.id === layer.id);
      // let currentLayer = this.layers[layerIndex];
      let layerStruct = { ...layer, loaded: false };

      // if (currentLayer) {
      //   console.log('has curret layer');
      //   layerStruct.loaded = currentLayer.loaded;
      //   this.layers[layerIndex] = layerStruct;
      // } else {
      this.layers.push(layerStruct);
      // }
      // if (layerIndex >= 0) return;
      // console.log(layerIndex);
    });

    this.loadImages();
  }

  async generateSentenceImg() {
    let senContainer = document.querySelector('.js-sentences-renderer');
    let senContainerBnd = senContainer.getBoundingClientRect();

    let ratio = senContainerBnd.height / senContainerBnd.width;
    let targetWidth = 1000;
    let targetHeight = targetWidth * ratio;

    // htmlToImage.toCanvas(senContainer, { canvasWidth: targetWidth, canvasHeight: targetHeight }).then(function (canvas) {
    //   document.body.appendChild(canvas);
    // });

    // return htmlToImage
    //   .toPixelData(senContainer, { canvasWidth: targetWidth, canvasHeight: targetHeight })
    //   .then(function (pixels) {
    //     return { pixels: pixels, width: targetWidth, height: targetHeight };
    //   })
    //   .catch(function (error) {
    //     console.error('oops, something went wrong!', error);
    //   });

    // a little bit dirty but there was an issue with generating the image on mobile
    // https://github.com/bubkoo/html-to-image/issues/292
    // by using two calls to htmlToImage it works
    const fontEmbedCss = await htmlToImage.getFontEmbedCSS(senContainer);

    return htmlToImage
      .toPng(senContainer, { fontEmbedCSS: fontEmbedCss, canvasWidth: targetWidth, canvasHeight: targetHeight, cacheBust: true })
      .then(() => {
        return htmlToImage.toPng(senContainer, {
          canvasWidth: targetWidth,
          canvasHeight: targetHeight,
          cacheBust: true
        });
      })
      .then(function (dataUrl) {
        return { base64: dataUrl, width: targetWidth, height: targetHeight };

        // var lala = new Image();
        // lala.src = dataUrl;
        // document.body.appendChild(lala);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }
}
