import { useLayoutEffect, useState, React, useRef, forwardRef } from 'react';
import { ReactComponent as IconPlay } from '../../assets/icons/icon-play.svg';
import { ReactComponent as IconColor } from '../../assets/icons/icon-color.svg';
import { ReactComponent as IconOptions } from '../../assets/icons/icon-options.svg';
import { ReactComponent as IconStop } from '../../assets/icons/icon-stop.svg';
import useOnClickOutside from 'use-onclickoutside';

import IconButton from '../IconButton';
import useWorkStore from '../../store/workStore';
import { useNavigate, useLocation } from 'react-router-dom';

function GeneratorToolbar(
  {
    onWorkSave,
    progress,
    isPlaying,
    onWorkTimelinePlay,
    onWorkTimelineStop,
    onWorkRemove,
    onWorkDownload,
    onWorkShare,
    progressSections
  },
  ref
) {
  const workState = useWorkStore((state) => state);
  const refProgress = useRef(null);
  const refToolbar = useRef(null);

  var menuOptions = [];

  if (navigator.share !== undefined) {
    menuOptions.push({
      label: 'Delen',
      onClick: onWorkShare
    });
  }

  if (navigator.share === undefined) {
    menuOptions.push({
      label: 'Downloaden',
      onClick: onWorkDownload
    });
  }

  if (workState.hasChanged) {
    menuOptions.push({
      label: 'Opslaan',
      onClick: onWorkSave
    });
  }

  if (workState.type === 'loaded') {
    menuOptions.push({
      label: 'Verwijderen',
      onClick: onWorkRemove
    });
  }

  const toggleTimeline = () => {
    if (isPlaying > 0) {
      onWorkTimelineStop();
    } else {
      onWorkTimelinePlay();
    }
  };
  // conditional options
  /*
    {
      label: 'Opslaan',
      onClick: onWorkSave
    },
  */

  var progressStyles = {};
  if (progress > 0) {
    progressStyles = {
      transform: `scaleX(${100 * progress}%)`
    };
  }

  useOnClickOutside(refToolbar, (ev) => {
    // if the click element is the trigger or if the clicked element is a child
    // of the trigger, do nothing.... nothing.... nothingk....
    // if (ev.target === triggerRef.current) return;
    // if (triggerRef.current.contains(ev.target)) return;

    onWorkTimelineStop();
  });

  return (
    <div className="generator-toolbar" ref={refToolbar}>
      {progressSections > 0 && (
        <div className="generator-toolbar__progress">
          <div className="generator-toolbar__progress_sections">
            {new Array(progressSections).fill(null).map((item, index) => {
              return <span key={`span-${index}`}></span>;
            })}
          </div>
          <div className="generator-toolbar__progress__inner" style={progressStyles} ref={ref}></div>
        </div>
      )}

      <div className="generator-toolbar__inner">
        {isPlaying && <IconButton Icon={IconStop} onClick={() => toggleTimeline()} />}
        {!isPlaying && <IconButton Icon={IconPlay} onClick={() => toggleTimeline()} />}
        <IconButton Icon={IconColor} popup={'color'} popupMenu={{}} />
        <IconButton className="really" Icon={IconOptions} popup={'menu'} popupMenu={menuOptions} />
      </div>
    </div>
  );
}

export default forwardRef(GeneratorToolbar);

// export default GeneratorToolbar;
