import { createPortal } from 'react-dom';
import { useRef, useEffect, useState } from 'react';

const PopupOverlay = ({ children }) => {
  const popupRoot = document.getElementById('popup-root');
  const el = useRef(document.createElement('div'));

  useEffect(() => {
    if (popupRoot) {
      popupRoot.appendChild(el.current);
    }
    return () => {
      if (popupRoot) popupRoot.removeChild(el.current);
    };
  }, [popupRoot]);

  return createPortal(children, el.current);
};

export default PopupOverlay;
