import { createPortal } from 'react-dom';
import { useRef, useEffect, useState } from 'react';

const OverlayPortal = ({ children }) => {
  const overlayRoot = document.getElementById('overlay-root');
  const el = useRef(document.createElement('div'));

  useEffect(() => {
    if (overlayRoot) {
      overlayRoot.appendChild(el.current);
    }
    return () => {
      if (overlayRoot) overlayRoot.removeChild(el.current);
    };
  }, [overlayRoot]);

  return createPortal(children, el.current);
};

export default OverlayPortal;
