import create from 'zustand';
import { devtools } from 'zustand/middleware';
import produce from 'immer';

// export const useStore = create<any>(
//   devtools((set) => ({
//       planetNames: [],
//       getPlanetNames: async () => {
//           const planetsData = await (
//               await fetch("https://swapi.dev/api/planets")
//           ).json();

//           set({ planetNames: planetsData.results.map((pd: any) => pd.name) });
//       },
//       setPlanetNames: (data: any) => set({ planetNames: data })
//   }))
// );

const useWorkStore = create(
  devtools(
    (set) => ({
      //
      type: 'new',
      hasChanged: false,
      setHasChanged: (hasChanged) => set({ hasChanged }),
      workColor: false,
      workId: null,
      setWorkColor: (color, hasChanged) => set({ workColor: color }),
      sentences: [],
      addSentence: (sentence, position = -1) => {
        // [...state.currentWork.layers].splice(position, 0, "Lene");
        set((state) => {
          const sentences = [...state.sentences];
          if (sentences.length > 0) {
            sentences.splice(position, 0, sentence);
          } else {
            sentences.push(sentence);
          }
          return {
            ...state,
            hasChanged: true,
            sentences: sentences
          };
        });
      },
      removeSentence: (sentenceId) => {
        set((state) => ({
          ...state,
          hasChanged: true,
          sentences: state.sentences.filter((l) => l.id !== sentenceId)
        }));
      },
      resetWork: () => {
        set((state) => ({
          ...state,
          type: 'new',
          workColor: false,
          hasChanged: false,
          workId: null,
          sentences: [],
          illustrations: []
        }));
      },
      illustrations: [],
      addIllustration: (illustration) => {
        set((state) => ({
          ...state,
          hasChanged: true,
          illustrations: [...state.illustrations, illustration]
        }));
      },
      replaceIllustration: (illustration) => {
        set((state) => {
          const index = state.illustrations.findIndex((item) => item.id === illustration.id);
          var illustrations = [...state.illustrations];
          illustrations[index] = illustration;
          return {
            ...state,
            illustrations: illustrations
          };
        });
      },
      removeIllustration: (illustrationId) => {
        set((state) => ({
          ...state,
          hasChanged: true,
          illustrations: state.illustrations.filter((l) => l.id !== illustrationId)
        }));
      },
      import: (work) => {
        set((state) => ({
          ...state,
          hasChanged: false,
          type: 'loaded',
          workId: work.id,
          workColor: work.workColor,
          sentences: work.sentences,
          illustrations: work.illustration
        }));
      },
      // immer example
      clearForest: () =>
        set(
          produce((state) => {
            state.lush.forest.contains = null;
          })
        )
    }),
    { name: 'workStore' }
  )
);

export default useWorkStore;

// const useWorkStore = create(
//   devtools(
//     (set) => ({

//       //
//       currentLineId: null,
//       thumb: "",
//       illustration: [],
//       lines: [],
//       nrOfEggsFound: 0,
//       setIncreaseFoundEggs: () => {
//         set((state) => ({
//           ...state,
//           nrOfEggsFound: state.nrOfEggsFound + 1,
//         }));
//       },
//       setThumb: (thumb) => {
//         set((state) => ({ ...state, thumb }));
//       },
//       setCurrentLineId: (currentLineId) => {
//         set((state) => ({
//           currentLineId: currentLineId,
//         }));
//       },
//       addIllustrationPart: (illustrationPart) => {
//         set((state) => ({
//           illustration: [...state.illustration, illustrationPart],
//         }));
//       },
//       addLine: (line, position = -1) => {
//         // [...state.currentWork.layers].splice(position, 0, "Lene");
//         set((state) => {
//           const lines = [...state.lines];
//           if (lines.length > 0) {
//             lines.splice(position, 0, line);
//           } else {
//             lines.push(line);
//           }
//           return {
//             ...state,
//             lines: lines,
//           };
//         });
//       },
//       removeLine: (lineId) => {
//         set((state) => ({
//           ...state,
//           lines: state.lines.filter((l) => l.id !== lineId),
//         }));
//       },
//       removeIllustrationPart: (illustrationPartId) => {
//         set((state) => ({
//           ...state,
//           illustration: state.illustration.filter(
//             (l) => l.id !== illustrationPartId
//           ),
//         }));
//       },
//       loadWork: (work) => {
//         set((state) => {
//           return {
//             ...state,
//             illustration: [...work.illustration],
//             lines: [...work.lines],
//           };
//         });
//       },

//       resetWork: () => {
//         set((state) => ({
//           illustration: [],
//           lines: [],
//           currentLineId: null,
//           nrOfEggsFound: 0,
//         }));
//       },
//     }),
//     { name: "workStore" }
//   )
// );
