//https://blog.logrocket.com/build-modal-with-react-portals/
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import Overlay from '../Overlay';
import { Transition } from 'react-transition-group';
import { useRef } from 'react';
import gsap from 'gsap';
import useStore from '../../store/store';
import useWorkStore from '../../store/workStore';

import ThemeSelector from './ThemeSelector';

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Pagination, Navigation } from 'swiper';

function SelectSentence({ onSelect }) {
  const nrOfSentences = 6;
  const workSentences = useWorkStore((state) => state.sentences);

  const sentences = useStore((state) => state.sentences);
  const themes = useStore((state) => state.themes);
  const [swiper, setSwiper] = useState(false);

  const [selectedSentence, setSelectedSentence] = useState(null);
  const [filteredSentences, setFilteredSentences] = useState([]);
  const [currentTheme, setCurrentTheme] = useState(null);
  const [distributedSentences, setDistributedSentences] = useState([]);

  const usedSentences = useMemo(() => {
    if (!workSentences) return [];
    return workSentences.map((sentence) => sentence.id);
  }, [workSentences]);

  // get all sentences and filter these by theme or sentences which
  // are already used in the current work
  const getSentences = useCallback(
    (limit = 0) => {
      // console.log('oe');
      let filteredSentences = sentences;
      // filter the theme
      if (currentTheme) {
        filteredSentences = sentences.filter((sentence) => {
          if (!sentence.themes) return false;
          return sentence.themes.indexOf(currentTheme) > -1;
        });
      }

      // filter the sentences which are already used
      if (usedSentences.length) {
        filteredSentences = filteredSentences.filter((sentence) => {
          return usedSentences.indexOf(sentence.id) === -1;
        });
      }

      // cap the sentences to the nrOfSentences
      var shuffledArray = filteredSentences.sort((a, b) => 0.5 - Math.random());
      // var shuffledArray = filteredSentences;

      if (limit) {
        shuffledArray = shuffledArray.slice(0, limit);
      }

      return shuffledArray;
    },
    [currentTheme, sentences, usedSentences]
  );

  const toggleTheme = (theme) => {
    if (currentTheme === theme) {
      setCurrentTheme(null);
    } else {
      setCurrentTheme(theme);
    }
  };

  const distributeSentences = (sentences) => {
    let columns = Math.ceil(sentences.length / nrOfSentences);
    let columnsArray = [];
    for (let i = 0; i < columns; i++) {
      columnsArray.push(sentences.slice(i * nrOfSentences, (i + 1) * nrOfSentences));
    }
    return columnsArray;
  };

  const onClickSelect = () => {
    onSelect({ sentence: selectedSentence });
  };
  const selectSentence = (sentence) => {
    setSelectedSentence(sentence);
  };

  // const sectionedSentences = distributeSentences(filteredSentences);

  const updateFilteredSentences = () => {};
  // --------------------------------------------------------------------------
  useEffect(() => {
    setFilteredSentences(getSentences());
    if (swiper) {
      swiper.slideTo(0, 0);
    }
  }, [currentTheme, swiper]);

  useLayoutEffect(() => {
    setSelectedSentence(null);
  }, [currentTheme]);

  useEffect(() => {
    setDistributedSentences(distributeSentences(filteredSentences));
  }, [filteredSentences]);

  return (
    <div className="select-content">
      <div className="select-content__header">
        <ThemeSelector themes={themes} currentTheme={currentTheme} onReset={() => setCurrentTheme(null)} onClick={toggleTheme} />
      </div>

      <div className="select-content__body">
        <Swiper
          className="add-content-swiper"
          spaceBetween={0}
          slidesPerView={1}
          autoHeight={true}
          onSlideChange={() => {
            setSelectedSentence(null);
          }}
          onSwiper={setSwiper}
          modules={[Pagination, Navigation]}
          pagination={{
            type: 'bullets',
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 5
          }}>
          {distributedSentences.map((sentences, index) => (
            <SwiperSlide key={`sentence-selection-group-${index}`}>
              <ul className="sentence-selection" key={`sentence-selection-group-${index}`}>
                {sentences.map((sentence, index) => (
                  <li
                    className={`sentence-selection__item ${selectedSentence === sentence ? 'is-active' : ''}`}
                    key={`sentence-s-${index}`}>
                    <span onClick={() => selectSentence(sentence)}>{sentence.text}</span>
                  </li>
                ))}
              </ul>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {selectedSentence && (
        <div className="select-content__footer">
          <button className="pill-button pill-button--large is-primary" onClick={onClickSelect}>
            Plaatsen
          </button>
        </div>
      )}
    </div>
  );
}

export default SelectSentence;
