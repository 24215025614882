import React, { useState, useCallback, useRef, useEffect, useLayoutEffect } from 'react';
import gsap from 'gsap';
import { useUpdateEffect } from 'react-use';
import useOnClickOutside from 'use-onclickoutside';
import PopupOverlay from './PopupOverlay';
import { Transition } from 'react-transition-group';
import ColorList from '../ColorList';
import { colors } from '../../common/Color';
import useWorkStore from '../../store/workStore';

function Option({ option, onMenuItemClick }) {
  return <li onClick={(ev) => onMenuItemClick(ev, option)}>{option.label}</li>;
}

// PopupMenu --------------------------------------------------
// https://nmingaleev.medium.com/how-to-create-a-custom-context-menu-with-react-hooks-30d011f205a0
function PopupWrapper({ options, onMenuClick, triggerChildren, children, position = 'top' }) {
  const nodeRef = useRef(null);
  const panelRef = useRef(null);
  const triggerRef = useRef(null);
  const [panelDirDown, setPanelDirDown] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 100, y: 100 });

  useOnClickOutside(panelRef, (ev) => {
    // if the click element is the trigger or if the clicked element is a child
    // of the trigger, do nothing.... nothing.... nothingk....
    if (ev.target === triggerRef.current) return;
    if (triggerRef.current.contains(ev.target)) return;

    setShowMenu(false);
  });

  // close the panel once an option is clicked
  useEffect(() => {
    function onClick() {
      setShowMenu(false);
    }
    if (showMenu) {
      panelRef.current.addEventListener('click', onClick);
    }

    return () => {
      if (panelRef.current) {
        panelRef.current.removeEventListener('click', onClick);
      }
    };
  }, [showMenu, panelRef]);

  const toggleTheMenu = useCallback(() => {
    let popupRoot = nodeRef.current.closest('#popup-root');
    let popupRootBounds = popupRoot.getBoundingClientRect();
    let panelBounds = panelRef.current.getBoundingClientRect();
    let triggerBounds = triggerRef.current.getBoundingClientRect();
    // console.log(triggerBounds);
    // console.log(triggerRef.current);
    let menuPosition;
    let panelSpacing = 8;

    let hOffset = 10;
    let centerOfTrigger = {
      x: triggerBounds.width / 2 + triggerBounds.x,
      y: triggerBounds.y - panelSpacing
    };

    // make sure the menu is positioned below when positioning above does not fit
    if (triggerBounds.y < panelBounds.height) {
      centerOfTrigger.y = triggerBounds.y + triggerBounds.height + panelBounds.height + panelSpacing;
      setPanelDirDown(true);
    }

    // if the center is too much to the right keep it in bound
    if (centerOfTrigger.x - popupRootBounds.left + panelBounds.width / 2 > popupRootBounds.width) {
      centerOfTrigger.x = popupRootBounds.left + popupRootBounds.width - panelBounds.width / 2 - hOffset;
    }

    // if the center is to much to the left keep it in bounds
    if (centerOfTrigger.x - panelBounds.width / 2 < 0) {
      centerOfTrigger.x = panelBounds.width / 2 + hOffset;
    }
    // if (centerOfTrigger.x < panelBounds.width + hOffset) {
    //   centerOfTrigger.x = panelBounds.width / 2 + hOffset;
    // }

    setMenuPosition(centerOfTrigger);
    setShowMenu(!showMenu);
  }, [showMenu, triggerRef, panelRef]);

  // handle panel animation
  // !! useUpdateEffect could cause problems, but might not
  useUpdateEffect(() => {
    var offset = 5;
    offset = panelDirDown ? -offset : offset;
    if (showMenu) {
      gsap.fromTo(panelRef.current, { opacity: 0, y: offset, x: '-50%' }, { duration: 0.1, opacity: 1, y: 0, x: '-50%' });
    } else {
      gsap.to(panelRef.current, { opacity: 0, duration: 0.1, y: offset });
    }
  }, [showMenu, panelDirDown]);

  // trick to add an event listener to the child element
  const child = React.cloneElement(triggerChildren, {
    ref: triggerRef,
    onClick: (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      toggleTheMenu();
      //   setShowMenu(true);
    }
  });

  // -- Transition -------------------------------------------------- //
  //   let onEnter = () => {
  //     gsap.fromTo(panelRef.current, { opacity: 0, y: 5 }, { duration: 0.1, opacity: 1, y: 0 });
  //   };
  //   let onExit = () => {
  //     gsap.to(panelRef.current, { opacity: 0, duration: 0.1 });
  //   };

  //

  const positionStyle = {
    left: `${menuPosition.x}px`,
    top: `${menuPosition.y}px`,
    pointerEvents: showMenu ? 'all' : 'none'
    // opacity: 0
  };

  return (
    <>
      <PopupOverlay>
        {/* <Transition in={showMenu} onEnter={onEnter} onExit={onExit} unmountOnExit timeout={500} nodeRef={nodeRef}> */}
        <div className="popup" style={positionStyle} ref={nodeRef}>
          <div className="popup__panel" ref={panelRef}>
            {children}
          </div>
        </div>
        {/* </Transition> */}
      </PopupOverlay>
      {child}
    </>
  );
}

export function PopupMenu({ options, onMenuClick, children, position = 'top' }) {
  // -- Event listeners ----------------------------------------------- //
  const onMenuItemClick = (ev, option) => {
    ev.preventDefault();
    ev.stopPropagation();

    // setShowMenu(false);

    if (onMenuClick) {
      onMenuClick(option);
    }
    if (option.onClick) {
      option.onClick(option);
    }
  };

  return (
    <PopupWrapper triggerChildren={children}>
      <div className="popup-menu">
        <ul>
          {options.map((option, index) => (
            <Option key={index} option={option} onMenuItemClick={onMenuItemClick}></Option>
          ))}
        </ul>
      </div>
    </PopupWrapper>
  );
}

export function PopupColor({ children }) {
  const workColor = useWorkStore((state) => state.workColor);
  const setWorkColor = useWorkStore((state) => state.setWorkColor);
  const setHasChanged = useWorkStore((state) => state.setHasChanged);

  const onColorClick = (color) => {
    setWorkColor(color, true);
    setHasChanged(true);
  };

  // -- Event listeners ----------------------------------------------- //
  return (
    <PopupWrapper triggerChildren={children}>
      <div className="popup-color">
        <ColorList currentColor={workColor} colors={colors} onColorSelect={onColorClick} />
      </div>
    </PopupWrapper>
  );
}
