import create from 'zustand';
import lines from '../api/lines.json';
import illustrations from '../api/illustrations.json';
import { devtools } from 'zustand/middleware';

// work sketch
/*

work = {
  layers:[
    {
      id:0, 
      text:{
        id:0, 
        text:"dit is waar ik ben",
        categories:["category1","category2"] 
      }, 
      illustration:{
        id:0,
        image:"https://picsum.photos/200/300",
        width:200,
        height:300,
        xSize:1,
        ySize:1,
        xPos:0,
        yPos:0,
        aspectRatio:1.5,
        categories:["category1","category2"]
      }}
  ]
}



*/
// let work = {
//   id: 0,
//   date: "2020-01-01",
//   name: "dit is de naam",
//   text: [
//     {
//       id: 0,
//       text: "dit is de tekst",
//       categories: ["category1", "category2"],
//     },
//   ],
//   illustration: {
//     base64: "",
//     layers: [
//       {
//         id: 0,
//         image: "https://picsum.photos/200/300",
//         width: 200,
//         height: 300,
//         xSize: 1,
//         ySize: 1,
//         xPos: 0,
//         yPos: 0,
//         aspectRatio: 1.5,
//         categories: ["category1", "category2"],
//       },
//     ],
//   },
// };

//  general store
const useStore = create(
  devtools(
    (set) => ({
      appLoading: false,
      setAppLoading: (loading) => set({ appLoading: loading }),
      isNotFirstUse: JSON.parse(localStorage.getItem('vv_isNotFirstUse')),
      setIsNotFirstUse: (isNotFirstUse) => {
        set((state) => {
          localStorage.setItem('vv_isNotFirstUse', isNotFirstUse);

          return {
            isNotFirstUse
          };
        });
      },
      setAfterFirstUse: () => {
        set((state) => {
          localStorage.setItem('vv_isNotFirstUse', true);
        });
      },
      //
      works: JSON.parse(localStorage.getItem('vv_works')) || [],
      saveWork: (work) => {
        set((state) => {
          // console.log('save work');
          const unixTimeStamp = Date.now();
          // console.log(work);
          let workObj = {
            id: unixTimeStamp,
            date: unixTimeStamp,
            thumb: work.thumb,
            workColor: work.workColor,
            sentences: work.sentences,
            illustration: [...work.illustrations]
            // lines: [...work.lines],
          };

          const works = [workObj, ...state.works];
          localStorage.setItem('vv_works', JSON.stringify(works));

          return {
            works: works
          };
        });
      },
      replaceWork: (work) => {
        set((state) => {
          // console.log('replace work');
          // console.log('save work');
          const unixTimeStamp = Date.now();
          // console.log(work);
          let workObj = {
            id: unixTimeStamp,
            date: unixTimeStamp,
            thumb: work.thumb,
            workColor: work.workColor,
            sentences: work.sentences,
            illustration: [...work.illustrations]
            // lines: [...work.lines],
          };

          // console.log(work);
          const index = state.works.findIndex((item) => item.id === work.id);
          // console.log(index);
          var works = [...state.works];
          works[index] = workObj;
          // console.log(works);
          localStorage.setItem('vv_works', JSON.stringify(works));

          return {
            works: works
          };
        });
      },
      removeWork: (workId) => {
        set((state) => {
          const works = state.works.filter((l) => l.id !== workId);
          localStorage.setItem('vv_works', JSON.stringify(works));

          return {
            ...state,
            works: works
          };
        });
      },

      //
      loaded: false,
      tokens: [],
      collectedTokens: JSON.parse(localStorage.getItem('vv_collectedTokens')) || [],
      setTokens: (tokens) => set({ tokens: tokens }),
      collectToken: (token) => {
        set((state) => {
          let collectedTokens = [...new Set([...state.collectedTokens, token])];

          localStorage.setItem('vv_collectedTokens', JSON.stringify(collectedTokens));

          // console.log(collectedTokens);
          return {
            ...state,
            collectedTokens
          };
        });
      },
      sentences: [],
      setSentences: (sentences) => set({ sentences: sentences }),
      illustrations: [],
      setIllustrations: (illustrations) => set({ illustrations }),
      themes: [],
      setCatThemes: (categories) => set({ themes: categories }),
      composerCats: [],

      // isFirstUse: JSON.parse(localStorage.getItem("dl_is_first_use")) || true,
      lines: [
        { id: 1, line: 'Lorem ipsum dolor site amet 1' },
        { id: 2, line: 'Lorem ipsum dolor site amet 2' },
        { id: 3, line: 'Lorem ipsum dolor site amet 3' }
      ],
      collectedEggs: JSON.parse(localStorage.getItem('dl_eggs_collection')) || [],
      easterEggs: [],
      setCatComposer: (categories) => set({ composerCats: categories }),

      // works: JSON.parse(localStorage.getItem('dl_works_collection')) || [],
      // currentWork: {
      //   currentLineId: null,
      //   usedLineIds: [],
      //   usedIllustrationIds: [],
      //   gridMatrix: [],
      //   illustration: [],
      //   text: [],
      //   work: {
      //     id: 0,
      //     date: false,
      //     lines: [],
      //     illustration: {
      //       layers: [],
      //     },
      //   },
      // },
      mainButton: [],
      selectedPokemon: null,
      setEasterEggs: (eggs) => set({ easterEggs: eggs }),
      // saveWork: (work) => {
      //   set((state) => {
      //     const unixTimeStamp = Date.now();

      //     let workWrapper = {
      //       id: unixTimeStamp,
      //       date: unixTimeStamp,
      //       name: unixTimeStamp,
      //       thumb: work.thumb,
      //       illustration: [...work.illustration],
      //       lines: [...work.lines]
      //     };

      //     let works = [workWrapper, ...state.works];
      //     localStorage.setItem('dl_works_collection', JSON.stringify(works));
      //     // setLocalStorage("collection", works);

      //     return {
      //       works: works
      //     };
      //   });
      // },
      addCollectedEgg: (egg) => {
        set((state) => {
          let collectedEggs = [...new Set([...state.collectedEggs, egg])];

          localStorage.setItem('dl_eggs_collection', JSON.stringify(collectedEggs));

          return {
            ...state,
            collectedEggs
          };
        });
      },

      setCurrentLineId: (currentLineId) => {
        set((state) => ({
          currentWork: {
            ...state.currentWork,
            currentLineId: currentLineId
          }
        }));
      },
      loadWork: (work) => {
        set((state) => {
          return {
            currentWork: { ...state.currentWork, layers: work }
          };
        });
      },
      resetWork: () => {
        set((state) => ({
          currentWork: { usedLineIds: [], layers: [], currentLineId: null }
        }));
      },
      removeLayer: (layer) => {
        set((state) => ({
          currentWork: {
            ...state.currentWork,
            layers: state.currentWork.layers.filter((l) => l.id !== layer.id)
          }
        }));
      },

      addText: (layer, position = -1) => {
        // [...state.currentWork.layers].splice(position, 0, "Lene");

        set((state) => {
          const text = [...state.currentWork.work.lines];
          if (lines.length > 0) {
            lines.splice(position, 0, layer);
          } else {
            lines.push(layer);
          }
          return {
            currentWork: {
              work: {
                lines: lines
              }
            }
          };
        });
      },

      addLayer: (layer, position = -1) => {
        // [...state.currentWork.layers].splice(position, 0, "Lene");

        set((state) => {
          const layers = [...state.currentWork.layers];
          if (layers.length > 0) {
            layers.splice(position, 0, layer);
          } else {
            layers.push(layer);
          }
          return {
            currentWork: {
              layers: layers
            }
          };
        });
      },
      setPokemon: (pokemon) => set((state) => ({ ...state, pokemon })),
      setFilter: (filter) => set((state) => ({ ...state, filter })),
      setSelectedPokemon: (selectedPokemon) => set((state) => ({ ...state, selectedPokemon }))
    }),
    { name: 'store' }
  )
);

// console.log(lines);
// console.log('aaa');
// // illustrations ------------------------------------------------------------
// const adjIllustrations = illustrations.data.entries.map((illustration) => {
//   let image = illustration.pngImage.shift();

//   if (image) {
//     illustration.categories = illustration.categories.map((cat) => cat.id);
//     illustration.image = { ...image, aspect: image.width / image.height };
//   }
//   return illustration;
// });

// useStore.setState((state) => ({ illustrations: adjIllustrations }));

// // lines ------------------------------------------------------------------
// const adjLines = lines.data.entries.map((line) => {
//   line.categories = line.categories.map((cat) => cat.id);
//   return line;
// });

// useStore.setState((state) => ({ lines: adjLines }));

// fetch("/starting-react/pokemon.json")
//   .then((resp) => resp.json())
//   .then((pokemon) => useStore.setState((state) => ({ ...state, pokemon })));

// export default useStore;
export default useStore;
