import logo from './logo.svg';
import './styles/main.scss';

// import 'context-filter-polyfill';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Provider as BusProvider, useBus, useListener } from 'react-bus';
import { MemoryRouter, Routes, Route } from 'react-router-dom';
import Loader from './components/Loader';
import PageIntroduction from './pages/PageIntroduction';
import PageGenerator from './pages/PageGenerator';
import PageDashboard from './pages/PageDashboard';
import PageDialog from './pages/PageDialog';
import DialogProvider from './components/Dialog/DialogProvider';
import Toolbar from './components/Toolbar';
import useStore from './store/store';
import loaderAnimation from './assets/animations/loader-lottie.json';
import Lottie from 'react-lottie-player';

import useWorkStore from './store/workStore';

function CheckLoaded() {
  const [initialPath, setInitialPath] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const loaded = useStore((state) => state.loaded);

  // useEffect(() => {
  //   setInitialPath(location.pathname);
  // }, []);

  useEffect(() => {
    if (!loaded) {
      navigate('/');
    }
  }, [loaded]);

  return null;
}

// --------------------------------------------------------------------------------------- //
function AppStructure() {
  const location = useLocation();
  const [showToolbar, setShowToolbar] = useState(false);

  useEffect(() => {
    setShowToolbar(['/dashboard', '/generator'].includes(location.pathname));
  }, [location.pathname]);

  return (
    <>
      <CheckLoaded />
      <div className="main__body">
        <Routes>
          <Route path="/" element={<Loader />} />
          <Route path="/intro" element={<PageIntroduction />} />
          <Route path="/generator" element={<PageGenerator />} />
          <Route path="/dashboard" element={<PageDashboard />} />
          <Route path="/dialog" element={<PageDialog />} />
        </Routes>
      </div>
      {showToolbar && (
        <div className="main__footer">
          <Toolbar />
        </div>
      )}
    </>
  );
}

function LoaderLayer() {
  const loading = useStore((state) => state.appLoading);

  return (
    loading && (
      <div className="loader-layer">
        <div className="loader-layer__backdrop"></div>
        <div className="loader-layer__container">
          <Lottie loop animationData={loaderAnimation} play style={{ width: '100%', height: '100%' }} />
        </div>
      </div>
    )
  );
}

// --------------------------------------------------------------------------------------- //
function App() {
  const workColor = useWorkStore((state) => state.workColor);

  useEffect(() => {
    // if (window.screen.orientation) {
    //   window.screen.orientation.lock('portrait');
    // }
    // console.log(window.screen.height);
  }, []);
  const styles = {
    // '--work-color': `"${workColor}"`
  };
  return (
    <>
      <DialogProvider>
        <BusProvider>
          <div className="App" style={styles} data-work-color={workColor}>
            <main>
              <MemoryRouter>
                <AppStructure />
              </MemoryRouter>
            </main>
            <div id="popup-root"></div>
            <div id="overlay-root"></div>
            <LoaderLayer />
            <div id="dialog-root"></div>
            {/* <div id="image-helper"></div> */}
          </div>
        </BusProvider>
      </DialogProvider>
    </>
  );
}

export default App;
